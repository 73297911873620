import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { InputPrice } from '@/components/ui/inputPrice'
import { useToast } from '@/components/ui/use-toast'
import {
  EstimateEstateFormValues,
  useEstimateEstateSchema,
} from '@/estate/hooks/useEstimateEstateSchema'
import { EstateForMainPageFragment, useUpdateEstateMutation } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type EstateEstimateFormProps = {
  estate: Pick<
    EstateForMainPageFragment,
    'id' | 'archived' | 'startEstimate' | 'endEstimate'
  >
}
export const EstateEstimateForm = ({ estate }: EstateEstimateFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const { schema } = useEstimateEstateSchema()

  const [updateEstate] = useUpdateEstateMutation({
    refetchQueries: ['getEstates'],
  })

  const form = useForm<EstimateEstateFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      startEstimate: estate.startEstimate ?? undefined,
      endEstimate: estate.endEstimate ?? undefined,
    },
  })

  const onSubmit = async ({
    startEstimate,
    endEstimate,
  }: EstimateEstateFormValues) => {
    if (!estate.id) return
    await updateEstate({
      variables: {
        id: estate.id,
        values: {
          startEstimate,
          endEstimate,
          estimationPublishedDate: new Date().toISOString(),
        },
      },
      onCompleted: () => {
        toast({
          description: t('EstateEstimatePriceCard.toast.success'),
          variant: 'success',
        })
      },
      onError: (error) => {
        console.error(error)
        toast({
          description: t('EstateEstimatePriceCard.toast.error'),
          variant: 'destructive',
        })
      },
    })
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="tw-flex tw-space-x-2 tw-items-center tw-w-full">
          <p className="tw-text-sm">{t('EstateEstimatePriceCard.between')}</p>
          <FormField
            control={form.control}
            name="startEstimate"
            render={({ field }) => (
              <FormItem className="tw-w-full">
                <FormControl>
                  <InputPrice
                    step={1}
                    suffix="€"
                    value={field.value}
                    onValueChange={(value) => field.onChange(value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <p className="tw-text-sm">{t('EstateEstimatePriceCard.and')}</p>
          <FormField
            control={form.control}
            name="endEstimate"
            render={({ field }) => (
              <FormItem className="tw-w-full">
                <FormControl>
                  <InputPrice
                    step={1}
                    suffix="€"
                    value={field.value}
                    onValueChange={(value) => field.onChange(value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            className="tw-justify-self-start"
            disabled={estate.archived}
          >
            {t('EstateEstimatePriceCard.button.estimate')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
