import * as React from 'react'
import { PlusCircleIcon } from 'lucide-react'
import { Column } from '@tanstack/react-table'

import { Separator } from '@/components/ui/separator'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { useTranslation } from 'react-i18next'

export type TableFilter = {
  value: string
  label: string
}

interface TableFacetedFilterProps<TData, TValue> {
  column?: Column<TData, TValue>
  title?: string
  options: TableFilter[]
}

export function TableFacetedFilter<TData, TValue>({
  column,
  title,
  options,
}: TableFacetedFilterProps<TData, TValue>) {
  const { t } = useTranslation()
  const columnFilterValue = column?.getFilterValue()
  const selectedValues = new Set(columnFilterValue as string[])

  return (
    <Popover modal>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="tw-h-8 tw-border-dashed">
          <PlusCircleIcon className="tw-mr-2 tw-h-4 tw-w-4" />
          {title}
          {selectedValues?.size > 0 && (
            <>
              <Separator orientation="vertical" className="tw-mx-2 tw-h-4" />
              <Badge
                variant="secondary"
                className="tw-rounded-sm tw-px-1 tw-font-normal lg:tw-hidden"
              >
                {selectedValues.size}
              </Badge>
              <div className="tw-hidden tw-space-x-1 lg:tw-flex">
                {selectedValues.size > 2 ? (
                  <Badge
                    variant="secondary"
                    className="tw-rounded-sm tw-px-1 tw-font-normal"
                  >
                    {t('TableFacetedFilters.selected', {
                      count: selectedValues.size,
                    })}
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => (
                      <Badge
                        variant="secondary"
                        key={option.value}
                        className="tw-rounded-sm tw-px-1 tw-font-normal"
                      >
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="tw-w-[200px] tw-p-0 tw-space-y-4"
        align="start"
      >
        <p>{title}</p>
        <Separator />
        {options.map((option) => (
          <div
            key={option.value}
            className="tw-flex tw-items-center tw-space-x-2"
          >
            <Checkbox
              id={option.value}
              checked={selectedValues.has(option.value)}
              onCheckedChange={(checked) => {
                if (checked) {
                  selectedValues.add(option.value)
                } else {
                  selectedValues.delete(option.value)
                }
                const filterValues = Array.from(selectedValues)
                column?.setFilterValue(
                  filterValues.length ? filterValues : undefined
                )
              }}
            />
            <Label
              htmlFor={option.value}
              className="tw-text-sm tw-font-medium tw-leading-none peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70"
            >
              {option.label}
            </Label>
          </div>
        ))}
        {selectedValues.size > 0 && (
          <div className="tw-flex tw-flex-col tw-justify-center">
            <Separator />
            <Button
              onClick={() => column?.setFilterValue(undefined)}
              className="tw-text-center"
              variant="ghost"
            >
              {t('TableFacetedFilters.clearFilters')}
            </Button>
          </div>
        )}
      </PopoverContent>
    </Popover>
  )
}
