import { InputProps } from '@/components/ui/input'
import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
} from 'react'
import { AutoComplete } from './autocomplete'
import {
  AddressAutoCompleteResult,
  useAddressAutoComplete,
} from './useAddressAutoComplete'

import { notNullish } from '../../utils/notNullish'

export enum AddressComboboxModeEnum {
  ADDRESS = 'address',
  ZIP_CODE = 'zipCode',
  CITY = 'city',
}

export type AddressComboboxProps = Omit<InputProps, 'value'> & {
  value?: string
  mode?: AddressComboboxModeEnum
  onSelectItem?: (value: AddressAutoCompleteResult) => void
}

const AddressComboboxInner = (
  {
    name,
    value,
    mode = AddressComboboxModeEnum.ADDRESS,
    disabled,
    onBlur,
    onChange,
    onSelectItem,
    ...props
  }: AddressComboboxProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const { getResults, data } = useAddressAutoComplete({
    type:
      mode !== AddressComboboxModeEnum.ADDRESS ? 'municipality' : 'housenumber',
    debounceWait: 250,
  })

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      getResults(event.target.value)
      onChange?.(event)
    },
    [getResults, onChange]
  )

  const handleSelectItem = useCallback(
    ({ value: itemValue }: { value: AddressAutoCompleteResult }) => {
      onSelectItem?.(itemValue)
    },
    [onSelectItem]
  )

  const items = useMemo(() => {
    function getLabel(item: AddressAutoCompleteResult) {
      switch (mode) {
        case AddressComboboxModeEnum.ADDRESS:
          return item.formatted
        case AddressComboboxModeEnum.ZIP_CODE:
          return [item.postalCode, item.city].filter(Boolean).join(' ')
        case AddressComboboxModeEnum.CITY:
          return [item.postalCode, item.city].filter(Boolean).join(' ')
      }
    }

    const labels: string[] = []
    return data
      .map((item) => {
        const label = getLabel(item)
        if (labels.includes(label)) return null
        labels.push(label)
        return {
          id: item.id,
          value: item,
          label: label,
        }
      })
      .filter(notNullish)
  }, [data, mode])

  return (
    <AutoComplete
      ref={ref}
      disabled={disabled}
      name={name}
      value={value}
      items={items}
      onInput={handleInputChange}
      onSelectItem={handleSelectItem}
      onBlur={onBlur}
      {...props}
    />
  )
}

export const AddressCombobox = forwardRef(AddressComboboxInner)
