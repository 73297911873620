import { Loading } from '@/common/atoms/Loading'
import { useAuthenticationStatus, useUserRoles } from '@nhost/react'
import React from 'react'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function AppRoute() {
  const { isLoading } = useAuthenticationStatus()
  const userRoles = useUserRoles()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')
  const navigate = useNavigate()

  if (isLoading) return <Loading center active />

  if (userRoles.includes('user') && redirectTo) {
    navigate(redirectTo)
  }

  if (userRoles.includes('user')) return <PrivateRoute />

  return <PublicRoute />
}
