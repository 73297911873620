import CrispSetUser from '@/common/atoms/CrispSetUser'
import { Loading } from '@/common/atoms/Loading'
import Page404 from '@/common/pages/Page404'
import MemberInvitationPage from '@/seller/pages/MemberInvitationPage'
import {
  useAgenciesSubscription,
  useAgencyMembersSubscription,
  useSellersSubscription,
} from '@gql'
import { useHasuraClaim, useUserId, useUserLocale } from '@nhost/react'
import { useStoreActions } from '@store/hooks'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'
import { langs, locales } from 'src/i18n'
import { AuthLayout } from '@/layout/AuthLayout'
import { ResetPasswordPage } from '@/user/pages/ResetPasswordPage'
import { ForgotPasswordForm } from '@/user/components/ForgotPasswordForm'
import { DefaultLayout } from '@/layout/DefaultLayout'
import { UserInfoPage } from '@/user/pages/UserInfoPage'
import SellersPage from '@/seller/pages/SellersPage'
import { EstateRoute } from './EstateRoute'
import { AdminUsersPage } from '@/agencyMember/pages/AdminUsersPage'
import AdminInvitationPage from '@/agencyMember/pages/AdminInvitationPage'
import { AgencyEstatesPage } from '@/estate/pages/AgencyEstatesPage'
import SellerRoute from './SellerRoute'
import { Outlet } from 'react-router-dom'
import { SentrySetUser } from '@/common/atoms/SentrySetUser'

const Protected = () => {
  const sellerMemberId = useHasuraClaim('seller-member-id')
  const agencyMemberId = useHasuraClaim('agency-member-id')
  const isSellerMember = !!sellerMemberId && sellerMemberId !== 'null'
  const isAgencyMember = !!agencyMemberId && agencyMemberId !== 'null'
  const isAcceptedMember = isSellerMember || isAgencyMember

  return isAcceptedMember ? <Outlet /> : <Page404 />
}

export default function PrivateRoute() {
  const userId = useUserId()
  const sellerMemberId = useHasuraClaim('seller-member-id')
  const isSellerMember = !!sellerMemberId && sellerMemberId !== 'null'

  const {
    i18n: { changeLanguage },
  } = useTranslation()

  // Update translation language with user's locale in DB
  const userLocale = useUserLocale()
  useEffect(() => {
    if (userLocale && langs.includes(userLocale as keyof typeof locales)) {
      changeLanguage(userLocale)
    }
  }, [changeLanguage, userLocale])

  // Subscribe to agencies
  const agenciesResult = useAgenciesSubscription({
    skip: !userId,
  })

  const setAgenciesSubscriptionResult = useStoreActions(
    (actions) => actions.agencies.setSubscriptionResult
  )

  const actions = useStoreActions((actions) => ({
    setCurrentId: actions.agency.setCurrentId,
    setSubscriptionResult: actions.agency.setSubscriptionResult,
  }))

  useEffect(() => {
    setAgenciesSubscriptionResult({
      entries: agenciesResult.data?.agency,
      loading: agenciesResult.loading,
      error: agenciesResult.error,
    })
    // TODO: Set current agency in store when i only have one
    actions.setCurrentId(agenciesResult.data?.agency[0]?.id)
    actions.setSubscriptionResult({
      result: agenciesResult.data?.agency[0],
      loading: agenciesResult.loading,
      error: agenciesResult.error,
    })
  }, [setAgenciesSubscriptionResult, actions, agenciesResult])

  // Subscribe to agencyMembers
  const agencyMembersResult = useAgencyMembersSubscription({
    skip: !userId,
  })

  const setAgencyMembersSubscriptionResult = useStoreActions(
    (actions) => actions.agencyMembers.setSubscriptionResult
  )

  useEffect(() => {
    setAgencyMembersSubscriptionResult({
      entries: agencyMembersResult.data?.agency_member,
      loading: agencyMembersResult.loading,
      error: agencyMembersResult.error,
    })
  }, [setAgencyMembersSubscriptionResult, agencyMembersResult])

  // Subscribe to sellers
  const sellersResult = useSellersSubscription({
    skip: !userId,
  })

  const setSellersSubscriptionResult = useStoreActions(
    (actions) => actions.sellers.setSubscriptionResult
  )

  useEffect(() => {
    setSellersSubscriptionResult({
      entries: sellersResult.data?.seller,
      loading: sellersResult.loading,
      error: sellersResult.error,
    })
  }, [setSellersSubscriptionResult, sellersResult])

  useEffect(() => {
    if (agenciesResult.error) {
      console.error(agenciesResult.error)
    }
  }, [agenciesResult.error])

  useEffect(() => {
    if (sellersResult.error) {
      console.error(sellersResult.error)
    }
  }, [sellersResult.error])

  useEffect(() => {
    if (agencyMembersResult.error) {
      console.error(agencyMembersResult.error)
    }
  }, [agencyMembersResult.error])

  return (
    <>
      <Loading
        center
        active={
          agenciesResult.loading ||
          sellersResult.loading ||
          agencyMembersResult.loading
        }
      />
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          {/* TODO REMOVE ? */}
          <Route path="change-password" element={<ResetPasswordPage />} />
          {/* TODO REMOVE ? */}
          <Route path="forgot-password" element={<ForgotPasswordForm />} />
          <Route path="login" element={<Navigate to="/" />} />
          <Route path="signup" element={<Navigate to="/" />} />
          <Route
            path="sellers/:sellerId/invitation"
            element={<MemberInvitationPage />}
          />
          <Route
            path="agency/:agencyId/invitation"
            element={<AdminInvitationPage />}
          />
        </Route>
        <Route element={<Protected />}>
          <Route element={<DefaultLayout />}>
            <Route
              index
              element={isSellerMember ? <SellerRoute /> : <AgencyEstatesPage />}
            />
            <Route path="user-info/:userId" element={<UserInfoPage />} />
            <Route path="estates/:estateId/*" element={<EstateRoute />} />
            <Route path=":slug/*" element={<SellerRoute />} />
            <Route path="sellers" element={<SellersPage />} />
            <Route path="sellers/:sellerId/*" element={<SellerRoute />} />
            <Route path="agency-members" element={<AdminUsersPage />} />
          </Route>
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
      <SentrySetUser />
      <CrispSetUser />
    </>
  )
}
