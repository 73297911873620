import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { useToast } from '@/components/ui/use-toast'
import { cn } from '@/lib/utils'
import {
  AgencyMemberFragment,
  EstateForSidebarFragment,
  useGetAgencyMembersQuery,
  useUpdateEstateMutation,
} from '@gql'
import { useStoreState } from '@store/hooks'
import { Check, ChevronsUpDown } from 'lucide-react'
import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { Label } from '@/components/ui/label'
import { getInitials } from '@utils/getInitials'

type EstateSidebarAgencyMemberSelectProps = {
  estate: Pick<EstateForSidebarFragment, 'id' | 'missionHeadId'>
}

export function EstateSidebarAgencyMemberSelect({
  estate,
}: EstateSidebarAgencyMemberSelectProps) {
  const { t } = useTranslation()
  const agencyId = useStoreState((state) => state.agency.currentId)
  const { toast } = useToast()
  const isAgencyMember = useIsAgencyMember()

  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(estate.missionHeadId || '')

  const [updateEstate] = useUpdateEstateMutation({
    refetchQueries: ['getEstates'],
  })

  const { data, loading, error } = useGetAgencyMembersQuery({
    variables: {
      agencyId: String(agencyId),
      archived: false,
    },
    skip: !agencyId,
    onError: (error) => {
      console.error(error)
      toast({
        title: t('EstateSidebarAgencyMemberSelect.toast.error'),
        variant: 'destructive',
      })
    },
  })

  const handleSelect = async (currentValue: string) => {
    if (!currentValue || !estate.id) return
    setValue(currentValue === value ? '' : currentValue)
    setOpen(false)

    await updateEstate({
      variables: {
        id: estate.id,
        values: {
          missionHeadId: currentValue,
        },
      },
      onCompleted: () => {
        toast({
          title: t('EstateSidebarAgencyMemberSelect.toast.success'),
          variant: 'success',
        })
      },
      onError: (error) => {
        console.error(error)
        toast({
          title: t('EstateSidebarAgencyMemberSelect.toast.error'),
          variant: 'destructive',
        })
      },
    })
  }

  return (
    <>
      <Label>{t('EstateSidebarAgencyMemberSelect.label')}</Label>
      <Popover modal open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild disabled={!isAgencyMember}>
          <Button
            variant="secondary"
            role="combobox"
            aria-expanded={open}
            className="tw-w-full tw-justify-between"
          >
            {value ? (
              <AgencyMemberItem
                member={data?.agency_member.find(
                  (member) => member.id === value
                )}
              />
            ) : (
              <span>
                {t('EstateSidebarAgencyMemberSelect.combobox.placeholder')}
              </span>
            )}
            <ChevronsUpDown className="tw-ml-2 tw-h-4 tw-w-4 tw-shrink-0 tw-opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="tw-p-0">
          <Command>
            <CommandInput
              placeholder={t(
                'EstateSidebarAgencyMemberSelect.combobox.searchPlaceholder'
              )}
            />
            <CommandList>
              <CommandEmpty>
                {t('EstateSidebarAgencyMemberSelect.combobox.empty')}
              </CommandEmpty>
              <CommandGroup>
                {error && (
                  <CommandItem disabled>
                    {t('EstateSidebarAgencyMemberSelect.combobox.error')}
                  </CommandItem>
                )}
                {loading && (
                  <CommandItem disabled>
                    {t('EstateSidebarAgencyMemberSelect.combobox.loading')}
                  </CommandItem>
                )}
                {!loading &&
                  data?.agency_member.map((member) => (
                    <CommandItem
                      key={member.id}
                      value={member.id}
                      onSelect={handleSelect}
                    >
                      <Check
                        className={cn(
                          'tw-mr-2 tw-h-4 tw-w-4',
                          value === member.id
                            ? 'tw-opacity-100'
                            : 'tw-opacity-0'
                        )}
                      />
                      {member.name}
                    </CommandItem>
                  ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  )
}

const AgencyMemberItem = ({ member }: { member?: AgencyMemberFragment }) => {
  if (!member) return
  return (
    <div className="tw-flex tw-items-center">
      <Avatar className="tw-flex tw-h-8 tw-w-8 tw-border">
        <AvatarFallback>
          {getInitials(member.name ?? member.email ?? '')}
        </AvatarFallback>
      </Avatar>
      <span className="tw-ml-2">{member.name}</span>
    </div>
  )
}
