'use client'

import { useCallback, useEffect, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import apiRadarIoSearchAutocompleteFetcher from './api.radar.io-search-autocomplete.fetcher'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { apiAdresseDataGouvFrFetcher } from './api-adresse.data.gouv.fr.fetcher'

export type AddressAutoCompleteResult = {
  id: string
  formatted: string
  addressLabel: string
  postalCode?: string
  city: string
  country: string
  x: number
  y: number
}

export type AddressAutoCompleteType =
  | 'housenumber'
  // | 'street'
  // | 'locality'
  | 'municipality'

export const useAddressAutoComplete = ({
  type = 'housenumber',
  limit = 5,
  debounceWait = 500,
  minQueryLength = 3,
}: {
  type?: AddressAutoCompleteType
  limit?: number
  debounceWait?: number
  minQueryLength?: number
}) => {
  const [query, setQuery] = useState('')
  const [debouncedQuery, setDebouncedQuery] = useState('')
  const [lastSearchedQuery, setLastSearchedQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<AddressAutoCompleteResult[]>([])

  useEffect(() => {
    const q = query
      .trim()
      .normalize('NFKD')
      .replace(/\p{Diacritic}/gu, '')

    if (q.length < minQueryLength) {
      setData([])
      return
    }

    const timeoutId = setTimeout(() => setDebouncedQuery(q), debounceWait)
    return () => clearTimeout(timeoutId)
  }, [debounceWait, minQueryLength, query])

  const search = useCallback(
    async (q: string) => {
      setLoading(true)
      try {
        // setData(await apiAdresseDataGouvFrFetcher(q, type, limit))
        setData(await apiRadarIoSearchAutocompleteFetcher(q, limit))
      } catch (error) {
        console.error('search error:', error)
      }
      setLastSearchedQuery(q)
      setLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [limit, type]
  )

  // NOTE call search method only if debouncedQuery is different from lastUsedQuery
  // and not currently loading
  useEffect(() => {
    if (debouncedQuery === lastSearchedQuery || loading) return
    search(debouncedQuery)
  }, [debouncedQuery, lastSearchedQuery, loading, search])

  return { getResults: setQuery, data }
}
