import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { ApplicationsStatsBlock } from '@/application/components/ApplicationsStatsBlock'
import { ApplicationsTimeline } from '@/application/components/ApplicationsTimeline'
import { ApplicationsTable } from '@/application/components/table/ApplicationsTable'
import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { useGetEstateForSalePageSubscription } from '@gql'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EmptySaleSvg from 'src/images/empty-sale.svg'
import settings from 'src/settings'
import EstateSettingModal from '../components/EstateSetting/EstateSettingModal'
import { EstateVisitSpanModal } from '../components/EstateVisitSpan/EstateVisitSpanModal'

export const EstateSalePage = () => {
  const { t } = useTranslation()
  const [isVisitSpanOpen, setIsVisitSpanOpen] = useState(false)
  const isAgencyMember = useIsAgencyMember()

  const { estateId } = useParams<{ estateId: string }>()

  const { loading, error, data } = useGetEstateForSalePageSubscription({
    variables: { id: estateId! },
    skip: estateId == null,
  })
  const estate = useMemo(() => data?.estate_by_pk, [data])

  const openPreviewPage = useCallback(() => {
    if (estate?.id == null) return
    // TODO allow page preview without isPublished or archived control
    window.open(`${settings.websiteUrl}/estate/${estate.id}`, '_blank')
  }, [estate?.id])

  const estateRounds = useMemo(
    () => estate?.estateRounds || [],
    [estate?.estateRounds]
  )
  const estateRound = useMemo(
    () =>
      estateRounds.length > 0 && estateRounds[0] != null ? estateRounds[0] : {},
    [estateRounds]
  )

  const showDetails =
    estateRound &&
    estate != null &&
    (estate.isPublished || estate.applicationAcceptedDate != null)

  return (
    <div>
      {loading ? (
        <Loading active />
      ) : error ? (
        <TextError error={error} />
      ) : (
        estate && (
          <div className="tw-h-[calc(100dvh-80px)]">
            {!showDetails && (
              <div className="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6">
                <EmptySaleSvg />
                <p className="tw-text-zinc-900 tw-font-semibold tw-text-lg">
                  {isAgencyMember
                    ? t('EstateSalePage.emptyState.title')
                    : t('EstateSalePage.emptyState.sellerTitle', {
                        agency: estate.seller.agency.name,
                      })}
                </p>
                <p className="tw-text-zinc-900 tw-text-sm tw-max-w-80 tw-text-center">
                  {t('EstateSalePage.emptyState.description')}
                </p>
                {isAgencyMember && (
                  <EstateSettingModal
                    estate={estate}
                    disabled={estate.archived}
                    readOnly={!isAgencyMember}
                  />
                )}
              </div>
            )}
            {showDetails && (
              <>
                <ApplicationsStatsBlock estate={estate} />
                <ApplicationsTimeline estate={estate} />
                <Separator />
                <div className="tw-p-8">
                  <div className="tw-flex tw-justify-between tw-items-baseline">
                    <h1 className="tw-text-[40px] tw-font-semibold">
                      {t('EstateSalePage.heading')}
                    </h1>
                    <div className="tw-flex tw-gap-2">
                      <Button
                        variant="secondary"
                        onClick={openPreviewPage}
                        disabled={estate.archived || !estate.isPublished}
                        title={t('EstateSalePage.previewButtonTitle')}
                      >
                        {t('EstateSalePage.previewButton')}
                      </Button>
                      <EstateVisitSpanModal
                        estateId={estate.id}
                        open={isVisitSpanOpen}
                        setOpenModal={setIsVisitSpanOpen}
                      />
                      <EstateSettingModal
                        estate={estate}
                        disabled={estate.archived}
                        readOnly={!isAgencyMember}
                      />
                    </div>
                  </div>
                  <ApplicationsTable estate={estate} />
                </div>
              </>
            )}
          </div>
        )
      )}
    </div>
  )
}
