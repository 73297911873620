import { ErrorMessage } from '@/common/atoms/ErrorMessage'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { InputMultipleDatePicker } from '@/components/ui/InputMultipleDatePicker'
import { Separator } from '@/components/ui/separator'
import { useToast } from '@/components/ui/use-toast'
import {
  EstateSettingVisitSpanFormValues,
  useEstateSettingVisitSpanSchema,
} from '@/estate/hooks/useEstateSettingVisitSpanSchema'
import {
  EstateForSalePageFragment,
  useUpsertVisitSpanMutation,
  Visit_Span_Insert_Input,
  Visit_Span_Updates,
} from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { parseDate } from '@utils/dates'
import { getTotalTimeSlots } from '@utils/getTotalTimeSlots'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import debounce from 'lodash.debounce'
import { Trash2Icon } from 'lucide-react'
import React, { useEffect, useMemo, useState } from 'react'
import {
  Controller,
  FieldErrors,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EstateSettingVisitSpanNestedFieldArray } from './EstateSettingVisitSpanNestedFieldArray'

export const DEFAULT_START_TIME = ['09', '00']

export const DEFAULT_END_TIME = ['19', '00']

// TODO make it better
const parseSpan = ({
  endTime,
  startTime,
}: {
  endTime: string | Date
  startTime: string | Date
}) => ({
  endTime: parseDate(endTime) ?? parseDate(startTime) ?? new Date(),
  startTime: parseDate(startTime) ?? parseDate(endTime) ?? new Date(),
})

export const getTimeAsDate = (timeAsArray: string[], dateAsDate: Date) => {
  const newDate = parseDate(dateAsDate)

  if (!newDate) {
    console.log(`Invalid date : ${dateAsDate}`)
    const today = new Date()
    today.setHours(Number(timeAsArray[0]), Number(timeAsArray[1]), 0, 0)
    return today
  }

  newDate.setHours(Number(timeAsArray[0]), Number(timeAsArray[1]), 0, 0)
  return newDate
}

type EstateSettingVisitSpanFormProps = {
  estate: Pick<EstateForSalePageFragment, 'id' | 'visit_spans' | 'estateRounds'>
  readOnly?: boolean
}

export const EstateSettingVisitSpanForm = ({
  estate,
  readOnly,
}: EstateSettingVisitSpanFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [upsertVisit] = useUpsertVisitSpanMutation({
    refetchQueries: ['getVisitSpansSlotsIsVisitedByEstatesIds'],
  })
  const debouncedUpsertVisit = debounce(upsertVisit, 500)

  const { schema } = useEstateSettingVisitSpanSchema()

  const defaultValues: EstateSettingVisitSpanFormValues = useMemo(() => {
    return {
      visitSpans: estate.visit_spans.map(
        (visitSpan: EstateForSalePageFragment['visit_spans'][number]) => ({
          visitId: visitSpan.id,
          date: parseDate(visitSpan.span?.[0]?.startTime) ?? new Date(),
          span: (visitSpan.span ?? []).map(parseSpan),
        })
      ),
    }
  }, [estate])

  const form = useForm<EstateSettingVisitSpanFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const { errors } = form.formState

  useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues])

  const [selectedDates, setSelectedDates] = useState<Date[]>(
    defaultValues.visitSpans?.map(
      (visitSpan) => parseDate(visitSpan.date) ?? new Date()
    ) || []
  )

  const handleDateSelect = (dates?: Date[]) => {
    if (!dates) return
    setSelectedDates(dates)
  }

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'visitSpans',
  })

  useEffect(() => {
    selectedDates.forEach((date) => {
      if (
        !fields.some(
          (field) =>
            field.date &&
            parseDate(field.date)?.toDateString() === date.toDateString()
        )
      ) {
        append({
          visitId: '',
          date,
          span: [
            {
              startTime: getTimeAsDate(DEFAULT_START_TIME, date),
              endTime: getTimeAsDate(DEFAULT_END_TIME, date),
            },
          ],
        })
      }
    })
  }, [selectedDates])

  const handleRemoveDate = (indexToRemove: number) => {
    const dateToRemove = fields[indexToRemove].date
    setSelectedDates((prevDates) =>
      prevDates.filter(
        (date) =>
          dateToRemove &&
          date.toDateString() !== parseDate(dateToRemove)?.toDateString()
      )
    )
    remove(indexToRemove)
  }

  const onSubmit = async (values: EstateSettingVisitSpanFormValues) => {
    if (!values.visitSpans) return

    let visitToCreate: Visit_Span_Insert_Input[] = []
    let visitToUpdate: Visit_Span_Updates[] = []

    const visitIdsToDelete: string[] = defaultValues.visitSpans
      .filter(
        (visitSpan) =>
          !values.visitSpans.some(
            (newVisitSpan) => newVisitSpan.visitId === visitSpan.visitId
          )
      )
      .map((visitSpan) => visitSpan.visitId)
      .filter((visitId): visitId is string => visitId !== undefined)

    values.visitSpans.forEach((visitSpan) => {
      if (visitSpan.visitId) {
        visitToUpdate.push({
          _set: {
            span: visitSpan.span,
          },
          where: {
            id: {
              _eq: visitSpan.visitId,
            },
          },
        })
      } else {
        visitToCreate.push({
          estateId: estate.id,
          span: visitSpan.span,
        })
      }
    })

    await debouncedUpsertVisit({
      variables: {
        valuesToCreate: visitToCreate,
        valuesToUpdate: visitToUpdate,
        idsToDelete: visitIdsToDelete,
      },
      onCompleted: () => {
        toast({
          description: t('EstateSettingVisitSpanForm.toast.success'),
          variant: 'success',
        })
      },
      onError: (error) => {
        console.error(error)
        toast({
          variant: 'destructive',
          title: t('EstateSettingVisitSpanForm.upsertVisit.error.title'),
          description: t(
            'EstateSettingVisitSpanForm.upsertVisit.error.description'
          ),
        })
      },
    })
  }
  const estateRound = estate.estateRounds[0] ?? {}

  const fromStartDate = useMemo(() => {
    return parseDate(estateRound.startDate)
  }, [estateRound])

  const toEndDate = useMemo(() => {
    return parseDate(estateRound.endDate)
  }, [estateRound])

  const visitSpans = form.watch('visitSpans')

  const onInvalid = (errors: FieldErrors<EstateSettingVisitSpanFormValues>) => {
    console.log({ errors })
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, onInvalid)}>
        {!readOnly && (
          <div className="tw-flex">
            <div className="tw-grow"></div>
            <Button
              type="submit"
              variant="secondary"
              className="tw-justify-self-end tw-w-fit tw-mt-3"
            >
              {t('EstateSettingVisitSpanForm.submit')}
            </Button>
          </div>
        )}
        <div className="tw-grid tw-pr-3">
          {!readOnly && (
            <div className="tw-flex tw-mt-3 tw-gap-10 tw-justify-between">
              <InputMultipleDatePicker
                placeholder={t(
                  'EstateSettingVisitSpanForm.datePicker.placeholder'
                )}
                value={selectedDates}
                onChange={handleDateSelect}
                fromDate={fromStartDate}
                toDate={toEndDate}
              />
            </div>
          )}
          {fields.map((field, index) => {
            return (
              <React.Fragment key={field.id}>
                <div className="tw-flex tw-justify-between tw-items-center tw-mt-3">
                  <div className="tw-flex tw-space-x-4 tw-items-center">
                    <p className="tw-font-medium tw-text-sm tw-capitalize">
                      {format(field.date, 'EEEE d MMMM yyyy', { locale: fr })}
                    </p>
                    <Controller
                      control={form.control}
                      name={`visitSpans.${index}.date`}
                      disabled={readOnly}
                      render={({ field }) => (
                        <input
                          type="hidden"
                          value={field.value?.toISOString()}
                        />
                      )}
                    />
                    {!readOnly && (
                      <Button
                        variant="ghost"
                        size="icon"
                        type="button"
                        className="tw-w-7 tw-h-7"
                        onClick={() => handleRemoveDate(index)}
                      >
                        <Trash2Icon className="tw-w-4 tw-h-4 tw-stroke-zinc-500" />
                      </Button>
                    )}
                  </div>
                  <p className="tw-text-xs tw-font-normal tw-text-zinc-500">
                    {visitSpans[index] &&
                      visitSpans[index].span.length &&
                      t('EstateSettingVisitSpanForm.spanLength', {
                        count: getTotalTimeSlots(visitSpans[index].span),
                      })}
                  </p>
                </div>
                <ErrorMessage>
                  {errors.visitSpans?.[index]?.span?.message}
                </ErrorMessage>
                <EstateSettingVisitSpanNestedFieldArray
                  nestId={index}
                  currentDate={field.date ?? new Date()}
                  readOnly={readOnly}
                  {...{ control: form.control }}
                />
                {index < fields.length - 1 && <Separator className="tw-mt-3" />}
              </React.Fragment>
            )
          })}
        </div>
        {!readOnly && (
          <div className="tw-flex">
            <div className="tw-grow"></div>
            <Button
              type="submit"
              variant="secondary"
              className="tw-justify-self-end tw-w-fit tw-mt-3"
            >
              {t('EstateSettingVisitSpanForm.submit')}
            </Button>
          </div>
        )}
      </form>
    </Form>
  )
}
