import { Button } from '@/components/ui/button'
import { useEstateMediaTypes } from '@/estate/hooks/useEstateMediaTypes'
import { EstateMedia } from '@/estateMedia/components/EstateMedia/EstateMedia'
import { EstateMediaFileUploadDialog } from '@/estateMedia/components/EstateMedia/EstateMediaFileUploadDialog'
import { useEstateMedias } from '@/estateMedia/hooks/useEstateMedias'
import { Estate_Media_Type_Group_Enum, Estate_Media_Type_Name_Enum } from '@gql'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type EstateOtherMediaListProps = {
  estateId: string
}
export const EstateOtherMediaList = ({
  estateId,
}: EstateOtherMediaListProps) => {
  const { t } = useTranslation()

  const { estateMedias } = useEstateMedias({
    estateId: estateId,
    typeGroup: Estate_Media_Type_Group_Enum.Other,
  })

  const { estateMediaTypes } = useEstateMediaTypes({
    group: Estate_Media_Type_Group_Enum.Other,
  })
  const estateMediaTypeDiagnosticOther = useMemo(() => {
    return estateMediaTypes.find(
      (type) => type.name === Estate_Media_Type_Name_Enum.Other
    )
  }, [estateMediaTypes])

  const [open, setOpen] = useState(false)

  const handleFileUpload = () => {
    setOpen(false)
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      {estateMedias.map((estateMedia) => (
        <EstateMedia
          key={estateMedia.id}
          estateId={estateId}
          estateMedia={estateMedia}
          canSetCustomName={true}
        />
      ))}
      <div className="tw-bg-gray-100 tw-flex tw-flex-row tw-gap-4 tw-items-center tw-justify-between tw-rounded-lg tw-p-4">
        <div>{t('EstateMedias.addFile')}</div>
        <Button variant="outline" onClick={() => setOpen(true)}>
          {t('common.add')}
        </Button>
      </div>
      <EstateMediaFileUploadDialog
        estateId={estateId}
        showToasts={true}
        open={open}
        onOpenChange={setOpen}
        type={estateMediaTypeDiagnosticOther!}
        onUpload={handleFileUpload}
        canSetCustomName={true}
      />
    </div>
  )
}
