import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'
import { useGetPresignedUrl } from '@/file/hooks/useGetPresignedUrl'
import { useNavigateSeller } from '@/seller/hooks/useNavigateSeller'
import { Estate_Media_Type_Group_Enum, EstateFragment } from '@gql'
import { ExternalLinkIcon } from 'lucide-react'
import React from 'react'
import HouseSvg from 'src/images/house.svg'
import { useEstateMedias } from '@/estateMedia/hooks/useEstateMedias'

type EstateCardMediaPreviewProps = {
  estateId: EstateFragment['id']
}

export const EstateCardMediaPreview = ({
  estateId,
}: EstateCardMediaPreviewProps) => {
  const navigate = useNavigateSeller()

  const { estateMedias, loading: useEstateMediasLoading } = useEstateMedias({
    estateId,
    typeGroup: Estate_Media_Type_Group_Enum.Media,
  })

  const fileId = estateMedias[0]?.fileId

  const { url, loading: useGetPresignedUrlLoading } = useGetPresignedUrl({
    fileId: fileId!,
    skip: !fileId,
  })

  if (useEstateMediasLoading || useGetPresignedUrlLoading) {
    return (
      <div className="tw-w-[304px] tw-h-[270px] tw-overflow-hidden">
        <Skeleton className="tw-h-10 tw-w-10" />
      </div>
    )
  }

  if (!estateMedias.length)
    return (
      <div className="tw-w-[304px] tw-h-[270px] tw-overflow-hidden">
        <div className="tw-relative tw-bg-zinc-50 tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-size-full">
          <Button
            variant="outline"
            className="tw-absolute tw-top-7 tw-left-7 tw-opacity-0 group-hover:tw-opacity-100"
            onClick={() => navigate(`estates/${estateId}`)}
          >
            <ExternalLinkIcon className="tw-h-4 tw-w-4" />
          </Button>
          <HouseSvg />
        </div>
      </div>
    )

  return (
    <div className="tw-w-[304px] tw-h-[270px] tw-overflow-hidden">
      <Button
        variant="outline"
        className="tw-absolute tw-top-7 tw-left-7 tw-opacity-0 group-hover:tw-opacity-100"
        onClick={() => navigate(`estates/${estateId}`)}
      >
        <ExternalLinkIcon className="tw-h-4 tw-w-4" />
      </Button>
      <img
        src={url ?? ''}
        alt="Estate picture"
        className="tw-object-cover tw-w-full tw-h-full"
      />
    </div>
  )
}
