import { WebappApplicationFragment } from '@gql'
import { closestTo, isValid } from 'date-fns'

export function getLatestVisitSlotDate(
  visitSlots: WebappApplicationFragment['buyer_member']['visit_slots']
) {
  // TODO MAKE IT WORK
  const visitDates = visitSlots.map((slot) => new Date(slot.createdAt))

  const closestDate = closestTo(new Date(), visitDates)

  const latestVisitSlot = visitSlots.find(
    (visitSlot) =>
      new Date(visitSlot.createdAt).getTime() === closestDate?.getTime()
  )?.startTime

  if (latestVisitSlot == null) return null
  const latestVisitSlotDate = new Date(latestVisitSlot)
  if (!isValid(latestVisitSlotDate)) return null
  return latestVisitSlotDate
}
