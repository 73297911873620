import { Button, ButtonProps } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { trpc } from 'src/trpc'

type EstateSendMailEstimateButtonProps = ButtonProps & {
  estateId: string
  refetchEstate: () => void
}
export const EstateSendMailEstimateButton = ({
  estateId,
  refetchEstate,
  ...props
}: EstateSendMailEstimateButtonProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const handleSendMailEstimate = async () => {
    if (props.disabled) return

    try {
      await trpc.estate.sendEstimateMail.mutate({
        estateId,
      })
      toast({
        title: t('EstateSendMailEstimateButton.toast.success'),
        variant: 'success',
      })
      refetchEstate()
    } catch (error) {
      console.error(error)
      toast({
        title: t('EstateSendMailEstimateButton.toast.failed'),
        variant: 'destructive',
      })
    }
  }

  return (
    <Button
      {...props}
      type="button"
      className="tw-justify-self-center tw-w-1/2"
      onClick={handleSendMailEstimate}
    >
      {t('EstateSendMailEstimateButton.button')}
    </Button>
  )
}
