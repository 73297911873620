import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useEstateSettingOpeningFormDatesSchema = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'Forms.errors' })

  const schema = useMemo(
    () =>
      yup.object().shape({
        startDate: yup.date().required(t('required')),
        startTime: yup.string().required(t('required')),
        endDate: yup.date().required(t('required')),
        endTime: yup.string().required(t('required')),
      }),
    [t]
  )

  return { schema }
}

export type EstateSettingOpeningFormDatesValues = yup.InferType<
  ReturnType<typeof useEstateSettingOpeningFormDatesSchema>['schema']
>
