import React from 'react'
import { MoreHorizontal } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { WebappSimpleEstateFragment } from '@gql'
import { useTranslation } from 'react-i18next'
import { useEstateArchiveDialog } from '@/estate/context/EstateArchiveDialogContext'

type EstatesTableActionDropdownProps = {
  estate: WebappSimpleEstateFragment
}

export const EstatesTableActionDropdown = ({
  estate,
}: EstatesTableActionDropdownProps) => {
  const { t } = useTranslation()
  const { setEstateId } = useEstateArchiveDialog()

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost">
            <MoreHorizontal className="tw-h-4 tw-w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>
            {t('EstatesTableActionDropdown.heading')}
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => setEstateId(estate.id)}>
            {estate.archived
              ? t('EstatesTableActionDropdown.active')
              : t('EstatesTableActionDropdown.archived')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
