/**
 * Sample code for Radar.io API
 * https://docs.radar.io/docs/search-autocomplete
 *
 * response example:
 * {
 *   "meta": {
 *     "code": 200
 *   },
 *   "addresses": [
 *     {
 *       "latitude": 48.857105,
 *       "longitude": 2.317442,
 *       "geometry": {
 *         "type": "Point",
 *         "coordinates": [
 *           2.317442,
 *           48.857105
 *         ]
 *       },
 *       "country": "France",
 *       "countryCode": "FR",
 *       "countryFlag": "🇫🇷",
 *       "distance": 344631,
 *       "borough": "7th Arrondissement",
 *       "city": "Paris",
 *       "number": "41",
 *       "neighborhood": "Hôtel des Invalides",
 *       "postalCode": "75007",
 *       "stateCode": "VP",
 *       "state": "Paris",
 *       "street": "Rue De Bourgogne",
 *       "layer": "address",
 *       "formattedAddress": "41 Rue De Bourgogne, 7th Arrondissement, Paris, VP 75007 FRA",
 *       "addressLabel": "41 Rue De Bourgogne"
 *     }
 *   ]
 * }
 */

import * as yup from 'yup'
import { AddressAutoCompleteResult } from './useAddressAutoComplete'

import config from 'src/settings'

const addressShape = yup.object().shape({
  latitude: yup.number().required(),
  longitude: yup.number().required(),
  // geometry: yup.object().shape({
  //   type: yup.string().required(),
  //   coordinates: yup.array().of(yup.number().required()).required(),
  // }),
  country: yup.string().required(),
  countryCode: yup.string().required(),
  // countryFlag: yup.string().required(),
  distance: yup.number().optional(),
  borough: yup.string().optional(),
  city: yup.string().optional(),
  number: yup.string().optional(),
  neighborhood: yup.string().optional(),
  postalCode: yup.string().optional(),
  // stateCode: yup.string().required(),
  // state: yup.string().required(),
  // street: yup.string().required(),
  layer: yup.string().optional(),
  formattedAddress: yup.string().required(),
  addressLabel: yup.string().required(),
})
type Address = yup.InferType<typeof addressShape>

const responseShape = yup.object().shape({
  meta: yup.object().shape({
    code: yup.number().required(),
  }),
  addresses: yup.array().of(addressShape),
})

function isValidAddress(address: Address): address is Address & {
  city: string
} {
  return address.city != null
}

export type ApiRadarIoSearchAutocompleteResponse = AddressAutoCompleteResult[]

export default async function apiRadarIoSearchAutocompleteFetcher(
  query: string,
  limit: number
): Promise<ApiRadarIoSearchAutocompleteResponse> {
  const response = await fetch(
    `https://api.radar.io/v1/search/autocomplete?query=${encodeURIComponent(query)}&limit=${limit}&countryCode=fr&lang=fr`,
    {
      headers: {
        Authorization: config.radarIo.apiKey,
      },
    }
  )
  if (response.status !== 200)
    throw new Error(`Bad response status: ${response.status}`)

  const json = await response.json()
  const data = await responseShape.validate(json)

  return (data.addresses ?? []).filter(isValidAddress).map(
    (address) =>
      ({
        id: address.formattedAddress,
        formatted: [
          address.addressLabel,
          address.postalCode,
          address.city,
          address.country,
        ]
          .filter(Boolean)
          .join(' '),
        // formatted: address.formattedAddress,
        addressLabel: address.addressLabel,
        postalCode: address.postalCode,
        city: address.city,
        country: address.country,
        x: address.longitude,
        y: address.latitude,
      }) satisfies AddressAutoCompleteResult
  )
}
