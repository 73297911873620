import React from 'react'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { useTranslation } from 'react-i18next'
import { Button } from './button'
export type ConfirmationDialogProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
  title?: string
  description?: React.ReactNode
  cancelLabel?: string
  confirmLabel?: string
  onConfirm?: () => void
  onCancel?: () => void
}
export function ConfirmationDialog({
  open,
  onOpenChange,
  title,
  description,
  cancelLabel,
  confirmLabel,
  onConfirm,
  onCancel,
}: ConfirmationDialogProps) {
  const { t } = useTranslation()
  const handleCancel = () => {
    onOpenChange(false)
    onCancel?.()
  }
  const handleConfirm = () => {
    onOpenChange(false)
    onConfirm?.()
  }
  return (
    <Dialog modal open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {title ?? t('ConfirmationDialog.defaultTitle')}
          </DialogTitle>
          <DialogDescription>
            {description ?? t('ConfirmationDialog.defaultDescription')}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant={'outline'} onClick={handleCancel}>
            {cancelLabel ?? t('ConfirmationDialog.defaultCancelLabel')}
          </Button>
          <Button onClick={handleConfirm}>
            {confirmLabel ?? t('ConfirmationDialog.defaultConfirmLabel')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
