import React, { forwardRef, useMemo } from 'react'
import { MultiSelect, MultiSelectProps } from '@/components/ui/multi-select'
import { useTranslation } from 'react-i18next'
import { XIcon } from 'lucide-react'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { getInitials } from '@utils/getInitials'
import { useGetEstateApplicantsQuery } from '@gql'
import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { EstateBuyerMemberScore } from './EstateBuyerMemberScore'

type EstateBuyerMemberMultiSelectProps = MultiSelectProps & {
  className?: string
  estateId: string
}

export const EstateBuyerMemberMultiSelect = forwardRef<
  HTMLDivElement,
  EstateBuyerMemberMultiSelectProps
>(({ className, estateId, ...props }, ref) => {
  const { t } = useTranslation()

  const { data, loading, error } = useGetEstateApplicantsQuery({
    variables: {
      id: estateId,
    },
  })

  const applications = data?.estate_by_pk?.applications ?? []

  const buyerMembers: MultiSelectProps['options'] = useMemo(() => {
    return applications
      .map(({ buyer_member }) => buyer_member)
      .filter(
        ({ id }, index, array) =>
          array.findIndex((bm) => bm.id === id) === index
      )
      .map((buyer_member) => ({
        value: buyer_member.id,
        label: buyer_member.name,
      }))
  }, [applications])

  const renderBuyerMember = (id: string) => {
    const buyerMember = buyerMembers.find(({ value }) => value === id)
    return (
      buyerMember && (
        <div
          key={id}
          className="tw-flex tw-items-center tw-justify-between tw-bg-zinc-50 tw-px-3 tw-py-2 tw-rounded-md "
        >
          <div className="tw-flex tw-items-center tw-gap-2">
            <Avatar className="tw-flex tw-h-8 tw-w-8 tw-border">
              <AvatarFallback>
                {getInitials(buyerMember.label ?? '')}
              </AvatarFallback>
            </Avatar>
            <p className="tw-text-sm">{buyerMember.label}</p>
          </div>
          <div className="tw-flex tw-items-center tw-gap-4">
            <EstateBuyerMemberScore estateId={estateId} buyerMemberId={id} />
            <XIcon
              className="tw-ml-2 tw-h-4 tw-w-4 tw-cursor-pointer"
              onClick={(event) => {
                event.stopPropagation()
                props.onValueChange(
                  props.defaultValue.filter((f) => f !== buyerMember.value)
                )
              }}
            />
          </div>
        </div>
      )
    )
  }

  if (loading) return <Loading center active />
  if (error) return <TextError error={error} />
  return (
    <div ref={ref}>
      <FormItem className="tw-flex tw-flex-col tw-w-full">
        <FormLabel>{t('EstateBuyerMemberMultiSelect.label')}</FormLabel>
        <FormControl>
          <MultiSelect
            options={buyerMembers}
            placeholder={t('EstateBuyerMemberMultiSelect.placeholder')}
            variant="outline"
            {...props}
          />
        </FormControl>
        <FormMessage />
      </FormItem>

      <div className="tw-my-4 tw-flex tw-flex-col tw-gap-2">
        {props.defaultValue.map(renderBuyerMember)}
      </div>
    </div>
  )
})

EstateBuyerMemberMultiSelect.displayName = 'EstateBuyerMemberMultiSelect'
