import { ErrorMessage } from '@/common/atoms/ErrorMessage'
import { Loading } from '@/common/atoms/Loading'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useGetEstateVisitSpansQuery } from '@gql'
import { parseDate } from '@utils/dates'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EstateVisitSpanRows } from './EstateVisitSpanRows'

type EstateVisitSpanModalProps = {
  open: boolean
  setOpenModal: (open: boolean) => void
  estateId: string
  disabled?: boolean
}

export const EstateVisitSpanModal = ({
  open,
  setOpenModal,
  estateId,
  disabled,
}: EstateVisitSpanModalProps) => {
  const { t } = useTranslation()

  const { data, loading, error } = useGetEstateVisitSpansQuery({
    variables: { id: estateId },
  })
  const visitSpans = useMemo(() => {
    return data?.visit_span ?? []
  }, [data])

  const sortedVisitSpans = useMemo(() => {
    return (
      visitSpans
        .flatMap((visitSpan) => visitSpan)
        .filter((visitSpan) => visitSpan.span?.[0]?.startTime)
        .sort((a, b) =>
          new Date(a.span[0].startTime) > new Date(b.span[0].startTime) ? 1 : -1
        ) ?? []
    )
  }, [visitSpans])

  const renderDialogContent = () => {
    if (loading) return <Loading center active />

    if (error)
      return <ErrorMessage>{t('EstateVisitSpanModal.error')}</ErrorMessage>

    if (!sortedVisitSpans.length)
      return <p>{t('EstateVisitSpanModal.noVisitSlots')}</p>

    const getVisitSpanLabel = (time: any) => {
      const parsedDate = parseDate(time)
      if (!parsedDate) {
        console.log('Error parsing date')
        return ''
      }
      return format(parsedDate, 'E dd/MM/yyyy', {
        locale: fr,
      })
    }

    return (
      <Tabs defaultValue={sortedVisitSpans[0]?.id}>
        <TabsList className="tw-h-auto tw-max-h-[100px] tw-flex-wrap tw-overflow-auto tw-gap-2 tw-bg-transparent tw-justify-stretch">
          {sortedVisitSpans.map((visitSpan) => (
            <TabsTrigger
              value={visitSpan.id}
              key={visitSpan.id}
              className="tw-border !tw-rounded-full tw-border-zinc data-[state=active]:tw-border-black"
            >
              {getVisitSpanLabel(visitSpan.span?.[0]?.startTime)}
            </TabsTrigger>
          ))}
        </TabsList>
        {sortedVisitSpans.map((visitSpan) => (
          <TabsContent value={visitSpan.id} key={visitSpan.id}>
            <EstateVisitSpanRows visitSpan={visitSpan} />
          </TabsContent>
        ))}
      </Tabs>
    )
  }

  return (
    <Dialog open={open} onOpenChange={setOpenModal}>
      <DialogTrigger asChild>
        <Button variant="secondary" disabled={disabled}>
          {t('EstateVisitSpanModal.title')}
        </Button>
      </DialogTrigger>
      <DialogContent className="tw-min-w-[500px]">
        <DialogHeader className="tw-mb-5">
          <DialogTitle>{t('EstateVisitSpanModal.title')}</DialogTitle>
        </DialogHeader>
        {renderDialogContent()}
      </DialogContent>
    </Dialog>
  )
}
