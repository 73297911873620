import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useGetEstateForDocumentsPageQuery } from '@gql'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { EstateCoownershipTab } from '../components/EstateCoownershipTab/EstateCoownershipTab'
import { EstateDiagnosticsTab } from '../components/EstateDiagnosticsTab/EstateDiagnosticsTab'
import { EstateEstateTab } from '../components/EstateEstateTab/EstateEstateTab'
import { EstateOtherTab } from '../components/EstateOtherTab/EstateOtherTab'
import { EstateNotFoundError } from '../utils/EstateNotFoundError'

export const EstateDocumentsPage = () => {
  const { t } = useTranslation()
  const { estateId } = useParams<{ estateId: string }>()

  const { loading, error, data } = useGetEstateForDocumentsPageQuery({
    variables: {
      id: estateId!,
    },
    skip: estateId == null,
    fetchPolicy: 'cache-and-network',
  })
  const estate = useMemo(() => data?.estate_by_pk, [data])

  return (
    <div className="tw-px-8 tw-py-6">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('EstateDocumentsPage.title')}
      </h1>
      <div className="tw-py-4 md:tw-py-6">
        {data == null && loading ? (
          <Loading active />
        ) : error ? (
          <TextError error={error} />
        ) : estate == null ? (
          <TextError error={new EstateNotFoundError(t)} />
        ) : (
          <Tabs defaultValue="estate" className="tw-w-[650px]">
            <TabsList>
              <TabsTrigger value="estate">
                {t('EstateDocumentsPage.estate')}
              </TabsTrigger>
              <TabsTrigger value="diagnostics">
                {t('EstateDocumentsPage.diagnostics')}
              </TabsTrigger>
              {estate.coownership?.isCoowned && (
                <TabsTrigger value="coownership">
                  {t('EstateDocumentsPage.coownership')}
                </TabsTrigger>
              )}
              <TabsTrigger value="other">
                {t('EstateDocumentsPage.other')}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="estate">
              <EstateEstateTab estateId={estate.id} />
            </TabsContent>
            <TabsContent value="diagnostics">
              <EstateDiagnosticsTab estate={estate} />
            </TabsContent>
            {estate.coownership?.isCoowned && (
              <TabsContent value="coownership">
                <EstateCoownershipTab estateId={estate.id} />
              </TabsContent>
            )}
            <TabsContent value="other">
              <EstateOtherTab estateId={estate.id} />
            </TabsContent>
          </Tabs>
        )}
      </div>
    </div>
  )
}
