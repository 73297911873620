import React from 'react'
import { useGetPresignedUrl } from '@/file/hooks/useGetPresignedUrl'
import { ApolloError } from '@apollo/client'
import { Files, useGetFileQuery } from '@gql'
import { Loading } from './Loading'
import { FileTextIcon } from 'lucide-react'

export type FilePreviewProps = {
  fileId?: Files['id'] | null
}
export const FilePreview = ({ fileId }: FilePreviewProps) => {
  const handleFileError = (error: ApolloError) => {
    console.error(error)
  }
  const handleUrlError = (error: Error) => {
    console.error(error)
  }

  const { data, loading: fileLoading } = useGetFileQuery({
    variables: {
      id: fileId!,
    },
    skip: !fileId,
    onError: handleFileError,
  })
  const file = data?.file

  const { url, loading: urlLoading } = useGetPresignedUrl({
    fileId: fileId!,
    skip: !fileId,
    onError: handleUrlError,
  })

  if (urlLoading || fileLoading) {
    return <Loading active />
  }
  if (!url) return null
  if (file?.mimeType?.startsWith('image/')) {
    return (
      <img
        src={url}
        alt="Preview"
        className="tw-h-44 tw-w-44 tw-object-cover tw-rounded-lg tw-mt-4"
      />
    )
  }
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <FileTextIcon />
    </a>
  )
}
