import { Card, CardHeader } from '@/components/ui/card'
import { useEstateMediaTypes } from '@/estate/hooks/useEstateMediaTypes'
import { EstateMedia } from '@/estateMedia/components/EstateMedia/EstateMedia'
import { EstateMediaTypeNameTranslation } from '@/estateMedia/components/EstateMedia/EstateMediaTypeNameTranslation'
import { MissingEstateMedia } from '@/estateMedia/components/EstateMedia/MissingEstateMedia'
import { NewEstateMedia } from '@/estateMedia/components/EstateMedia/NewEstateMedia'
import { useEstateMedias } from '@/estateMedia/hooks/useEstateMedias'
import {
  EstateMediaTypeFragment,
  Estate_Media_Type_Group_Enum,
  Estate_Media_Type_Name_Enum,
  WebappEstateMediaFragment,
} from '@gql'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const expectedMediaTypeConfig = [
  { name: Estate_Media_Type_Name_Enum.CoownershipRegulation, isMultiple: true },
  { name: Estate_Media_Type_Name_Enum.LotsPlan },
  { name: Estate_Media_Type_Name_Enum.DatedPreState },
  { name: Estate_Media_Type_Name_Enum.DatedState },
  { name: Estate_Media_Type_Name_Enum.CoownershipEpc },
  { name: Estate_Media_Type_Name_Enum.GeneralMeetingMinutes, isMultiple: true },
  { name: Estate_Media_Type_Name_Enum.FundCalls },
  { name: Estate_Media_Type_Name_Enum.BuildingMaintenanceLog },
  { name: Estate_Media_Type_Name_Enum.CommonAreaDiagnostics },
  { name: Estate_Media_Type_Name_Enum.CoownershipSummarySheet },
]

export type EstateCoownershipTabProps = {
  estateId: string
}
export const EstateCoownershipTab = ({
  estateId,
}: EstateCoownershipTabProps) => {
  const { t } = useTranslation()

  const { estateMedias: allEstateMedias } = useEstateMedias({
    estateId: estateId,
    typeGroup: Estate_Media_Type_Group_Enum.Coownership,
  })
  const estateMedias = allEstateMedias.filter((em) => !em.isCensored)

  const { estateMediaTypes } = useEstateMediaTypes({
    group: Estate_Media_Type_Group_Enum.Coownership,
  })

  const expectedMediaTypes = useMemo(() => {
    return estateMediaTypes.filter((type) =>
      expectedMediaTypeConfig.find((config) => config.name === type.name)
    )
  }, [estateMediaTypes])

  const missingMediaTypes = useMemo(() => {
    return expectedMediaTypes.filter(
      (type) => !estateMedias.find((em) => em.type.id === type.id)
    )
  }, [estateMedias, expectedMediaTypes])

  const otherType = useMemo(() => {
    return estateMediaTypes.find(
      (type) => type.name === Estate_Media_Type_Name_Enum.Other
    )
  }, [estateMediaTypes])

  const estateMediasGroupedByType = useMemo(() => {
    return estateMedias.reduce(
      (acc, media) => {
        const type = media.type
        if (!acc[type.id]) {
          acc[type.id] = []
        }
        acc[type.id].push(media)
        return acc
      },
      {} as Record<EstateMediaTypeFragment['id'], WebappEstateMediaFragment[]>
    )
  }, [estateMedias])

  const renderMultipleEstateMedias = (
    key: string,
    estateMedias: WebappEstateMediaFragment[]
  ) => {
    return (
      <Card key={key} className="tw-p-4">
        <CardHeader className="tw-text-xl tw-font-semibold tw-mb-4">
          <EstateMediaTypeNameTranslation type={estateMedias[0].type} />
        </CardHeader>
        {estateMedias.map((estateMedia) => (
          <EstateMedia
            key={estateMedia.id}
            estateId={estateId}
            estateMedia={estateMedia}
          />
        ))}
        {expectedMediaTypeConfig.find(
          (config) =>
            config.name === estateMedias[0].type.name && config.isMultiple
        ) && (
          <MissingEstateMedia type={estateMedias[0].type} estateId={estateId} />
        )}
      </Card>
    )
  }

  const renderSingleEstateMedia = (estateMedia: WebappEstateMediaFragment) => {
    return (
      <EstateMedia
        key={estateMedia.id}
        estateId={estateId}
        estateMedia={estateMedia}
      />
    )
  }

  return (
    <>
      <h2 className="tw-text-2xl tw-font-semibold tw-mb-5">
        {t('EstateCoownershipTab.title')}
      </h2>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-w-[650px]">
        {Object.entries(estateMediasGroupedByType).map(
          ([mediaType, estateMedias]) => {
            const conf = expectedMediaTypeConfig.find(
              (config) => config.name === estateMedias[0].type.name
            )

            if (conf?.isMultiple)
              return renderMultipleEstateMedias(mediaType, estateMedias)
            return renderSingleEstateMedia(estateMedias[0])
          }
        )}
        {missingMediaTypes.map((missingMediaType) => (
          <MissingEstateMedia
            key={missingMediaType.id}
            type={missingMediaType}
            estateId={estateId}
          />
        ))}
        {otherType && <NewEstateMedia estateId={estateId} type={otherType} />}
      </div>
    </>
  )
}
