import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSellers from '@/seller/hooks/useSellers'
import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { SelectProps } from '@radix-ui/react-select'
import { cn } from '@/lib/utils'
import { SellerFragment } from '@gql'

type SellerSelectProps = SelectProps & {
  field: ControllerRenderProps<any>
  className?: string
  filter?: (seller: SellerFragment) => boolean
  showLabel?: boolean
}
export const SellerSelect = forwardRef<HTMLInputElement, SellerSelectProps>(
  ({ field, className, filter, showLabel = true, ...props }, ref) => {
    const { t } = useTranslation()
    const [value, setValue] = useState<string | undefined>(field.value)

    const { sellers: allSellers, loading, error } = useSellers()
    const sellers = useMemo(() => {
      if (filter) {
        return allSellers?.filter(filter)
      }
      return allSellers
    }, [allSellers, filter])

    useEffect(() => {
      if (!sellers) return
      if (!field.value) return
      const seller = sellers.find((seller) => seller.id === field.value)
      if (!seller) return
      setValue(seller?.id)
    }, [field.value, sellers])

    const handleChange = (value: string) => {
      setValue(value)
      field.onChange(value)
    }

    if (loading) return <Loading center active />
    if (error) return <TextError error={error} />
    return (
      <FormItem className={cn(className, 'tw-w-full')}>
        {showLabel && (
          <FormLabel>
            {t('SellerSelect.label')}
            {props.required ? '*' : ''}
          </FormLabel>
        )}
        <Select {...props} onValueChange={handleChange} value={value}>
          <FormControl>
            <SelectTrigger className="tw-w-full">
              <SelectValue
                ref={ref}
                placeholder={t('SellerSelect.placeholder')}
              />
            </SelectTrigger>
          </FormControl>
          <SelectContent>
            {Object.values(sellers!).map((seller) => (
              <SelectItem value={seller.id} key={seller.id}>
                {seller.name}
              </SelectItem>
            ))}
          </SelectContent>
          <FormMessage />
        </Select>
      </FormItem>
    )
  }
)

SellerSelect.displayName = 'SellerSelect'
