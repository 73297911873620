import useQueryParams from '@/common/hooks/useQueryParams'
import React, { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Title } from '@/common/atoms/Title'
import { AdminSigninForm } from '../components/AdminSigninForm'
import { useToast } from '@/components/ui/use-toast'
import { trpc } from 'src/trpc'
import { AgencyMemberFragment } from '@gql'
import useAgency from '../../agency/hooks/useAgency'
import { Loading } from '@/common/atoms/Loading'
import { useHasuraClaim } from '@nhost/react'
import { ErrorMessage } from '@/common/atoms/ErrorMessage'

type Params = {
  id?: string
  token: string
}

export default function AdminInvitationPage() {
  const agencyMemberId = useHasuraClaim('agency-member-id')
  const isAgencyMember = !!agencyMemberId && agencyMemberId !== 'null'

  const { t } = useTranslation()
  const { toast } = useToast()
  const { agencyId } = useParams<{ agencyId: string }>()
  const { id, token } = useQueryParams<Params>()

  const { agency } = useAgency(agencyId)

  const [isLoading, setIsLoading] = useState(false)

  const [currentUser, setCurrentUser] = useState<
    AgencyMemberFragment | undefined
  >(undefined)

  const [hasSetPassword, setHasSetPassword] = useState(false)
  const [invitationTokenExpired, setInvitationTokenExpired] = useState(false)

  useEffect(() => {
    if (!id) return
    if (!token) return
    setIsLoading(true)
    setTimeout(() => {
      trpc.agencyMember.acceptAgencyMemberInvitation
        .mutate({ agencyMemberId: id, token })
        .then((data) => {
          toast({
            title: t(
              'AdminInvitationPage.acceptAgencyMemberInvitation.success.title'
            ),
            description: t(
              'AdminInvitationPage.acceptAgencyMemberInvitation.success.description'
            ),
            variant: 'success',
          })
          setCurrentUser(data)
          setHasSetPassword(!!data.hasSetPassword)
        })
        .catch((error) => {
          console.error(error, JSON.stringify(error))
          if (error.message === 'Invitation token expired') {
            setInvitationTokenExpired(true)
            toast({
              variant: 'destructive',
              title: t(
                'AdminInvitationPage.acceptAgencyMemberInvitation.invitationTokenExpired.title'
              ),
              description: t(
                'AdminInvitationPage.acceptAgencyMemberInvitation.invitationTokenExpired.description'
              ),
            })
          } else {
            console.error(error)
            toast({
              variant: 'destructive',
              title: t(
                'AdminInvitationPage.acceptAgencyMemberInvitation.error.title'
              ),
              description: t(
                'AdminInvitationPage.acceptAgencyMemberInvitation.error.description'
              ),
            })
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, 2000) // Quick fix: wait for Nhost Auth to be ready
  }, [agencyMemberId, token])

  if (invitationTokenExpired)
    return (
      <ErrorMessage>
        {t('AdminInvitationPage.invitationTokenExpired')}
      </ErrorMessage>
    )

  if (isAgencyMember && hasSetPassword) return <Navigate to="/" />

  return (
    <div className="tw-space-y-5 tw-mb-5">
      <Title>{t('AdminInvitationPage.title')}</Title>
      {(isLoading || !currentUser) && <Loading active />}
      {!isLoading && currentUser && (
        <>
          <h1 className="tw-text-2xl tw-font-bold">
            {t('AdminInvitationPage.heading', {
              agency: agency?.name,
            })}
          </h1>

          <AdminSigninForm
            agencyMember={currentUser}
            onSuccess={(newHasSetPassword) => {
              setIsLoading(true)
              setHasSetPassword(newHasSetPassword)
            }}
          />
        </>
      )}
    </div>
  )
}
