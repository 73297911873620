import { Button } from '@/components/ui/button'
import { useEstateMediaTypes } from '@/estate/hooks/useEstateMediaTypes'
import { useEstateMedias } from '@/estateMedia/hooks/useEstateMedias'
import { Estate_Media_Type_Group_Enum, Estate_Media_Type_Name_Enum } from '@gql'
import { ImageUp } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EstateMedia } from '../EstateMedia/EstateMedia'
import { EstateMediaMultipleFileUploadDialog } from '../EstateMedia/EstateMediaMultipleFileUploadDialog'

export type EstateMediasVideosTabProps = {
  estateId: string
}

export const EstateMediasVideosTab = ({
  estateId,
}: EstateMediasVideosTabProps) => {
  const { t } = useTranslation()

  const { estateMedias: estateMediasMedia } = useEstateMedias({
    estateId: estateId,
    typeGroup: Estate_Media_Type_Group_Enum.Media,
  })

  const estateMedias = useMemo(() => {
    return estateMediasMedia.filter(
      (em) => em.type.name === Estate_Media_Type_Name_Enum.Video
    )
  }, [estateMediasMedia])

  const { estateMediaTypes } = useEstateMediaTypes({
    group: Estate_Media_Type_Group_Enum.Media,
  })
  const estateMediaTypeVideo = useMemo(() => {
    if (!estateMediaTypes) return undefined
    return estateMediaTypes.find(
      (type) => type.name === Estate_Media_Type_Name_Enum.Video
    )
  }, [estateMediaTypes])

  const [isNewMediaOpen, setIsNewMediaOpen] = useState(false)

  const handleFileUpload = () => {
    setIsNewMediaOpen(false)
  }

  return (
    <>
      <h2 className="tw-text-2xl tw-font-semibold tw-mb-5">
        {t('EstateMediasVideosTab.title')}
      </h2>
      <div className="tw-flex tw-flex-wrap tw-gap-4 tw-w-[650px]">
        {estateMedias.map((estateMedia) => (
          <EstateMedia
            key={estateMedia.id}
            estateId={estateId}
            estateMedia={estateMedia}
            variant="video"
          />
        ))}
        <Button
          variant="secondary"
          onClick={() => setIsNewMediaOpen(true)}
          className="tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center tw-w-[180px] tw-h-[180px] tw-bg-gray-100 tw-rounded-lg"
        >
          <ImageUp />
          {t('EstateMediasVideosTab.add')}
        </Button>
      </div>
      <EstateMediaMultipleFileUploadDialog
        estateId={estateId}
        showToasts={true}
        accept="video/*"
        open={isNewMediaOpen}
        onOpenChange={() => setIsNewMediaOpen(false)}
        type={estateMediaTypeVideo!}
        onUpload={handleFileUpload}
      />
    </>
  )
}
