import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { configureCrisp } from '@utils/crisp'
import { configureSentry } from '@utils/sentry'
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { isLocal } from './settings'

if (isLocal) {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

configureSentry()
configureCrisp()

const root = createRoot(document.getElementById('root')!)

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
