import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { useToast } from '@/components/ui/use-toast'
import { EstateNotFoundError } from '@/estate/utils/EstateNotFoundError'
import {
  GetEstateForMandatePageDocument,
  GetMandateDocument,
  MandateFragment,
  useCreateMandateMutation,
  useGetEstateForMandatePageQuery,
  useGetMandateQuery,
  useUpdateMandateMutation,
} from '@gql'
import debounce from 'lodash.debounce'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MandateInfoForm } from '../components/MandateInfoForm'
import { MandateStatusCard } from '../components/MandateStatusCard'

export const MandatePage = () => {
  const { t } = useTranslation()
  const { estateId } = useParams<{ estateId: string }>()

  const {
    loading: estateLoading,
    error: estateError,
    data: estateData,
  } = useGetEstateForMandatePageQuery({
    variables: {
      id: estateId!,
    },
    skip: estateId == null,
    fetchPolicy: 'cache-and-network',
  })
  const estate = useMemo(() => estateData?.estate_by_pk, [estateData])

  // TODO: why estate.mandate is not directly used instead?
  const {
    loading: mandateLoading,
    error: mandateError,
    data: mandateData,
  } = useGetMandateQuery({
    variables: {
      id: estate?.mandate?.id!,
    },
    skip: estate?.mandate?.id == null,
  })

  const mandate = mandateData?.mandate_by_pk

  const { toast } = useToast()
  const [createMandate] = useCreateMandateMutation({
    refetchQueries: [GetEstateForMandatePageDocument, GetMandateDocument], // TODO get new data from response instead of refreshing
  })
  const [updateMandate] = useUpdateMandateMutation({
    refetchQueries: [GetMandateDocument], // TODO get new data from response instead of refreshing
  })

  const onCompleted = () => {
    toast({
      description: t('MandateInfoForm.toast.success'),
      variant: 'success',
    })
  }

  const onError = (error: Error) => {
    console.error(error)
    toast({
      description: t('MandateInfoForm.toast.error'),
      variant: 'destructive',
    })
  }

  const debouncedUpdateMandate = debounce(
    (values: Partial<MandateFragment>) => {
      if (estate?.mandate?.id == null) return
      return updateMandate({
        variables: {
          id: estate.mandate.id,
          values,
        },
        onCompleted,
        onError,
      })
    },
    500
  )

  const debouncedCreateMandate = debounce(
    (values: Partial<MandateFragment>) => {
      if (estate?.id == null) return
      return createMandate({
        variables: {
          values: {
            ...values,
            estateId: estate.id,
          },
        },
        onCompleted,
        onError,
      })
    },
    500
  )

  const handleSubmit = useCallback(
    async (
      values: Partial<MandateFragment>,
      mandateId?: MandateFragment['id']
    ) => {
      if (estate?.id == null) return null
      if (mandateId) {
        await debouncedUpdateMandate(values)
        return mandateId
      } else {
        const res = await debouncedCreateMandate(values)
        return res?.data?.insert_mandate_one?.id
      }
    },
    [debouncedCreateMandate, debouncedUpdateMandate, estate?.id]
  )

  return (
    <div className="tw-py-6 tw-px-8">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('MandatePage.heading')}
      </h1>
      <div className="tw-w-full tw-grid md:tw-grid-cols-[1fr_40%] tw-gap-6 tw-py-4 md:tw-py-6">
        {(estateData == null && estateLoading) ||
        (mandateData == null && mandateLoading) ? (
          <Loading active />
        ) : estateError ? (
          <TextError error={estateError} />
        ) : mandateError ? (
          <TextError error={mandateError} />
        ) : estate == null ? (
          <TextError error={new EstateNotFoundError(t)} />
        ) : (
          <>
            <MandateInfoForm
              mandate={mandate}
              estate={estate}
              onSubmit={handleSubmit}
            />
            <MandateStatusCard
              mandate={mandate}
              agencyId={estate.seller.agencyId}
            />
          </>
        )}
      </div>
    </div>
  )
}
