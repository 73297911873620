import { Application_Status_Enum } from '@gql'

export type GetEstateStatsProps = {
  applications?: { status: Application_Status_Enum }[]
  visit_spans?: {
    visit_slots: { isVisitDone: boolean }[]
  }[]
}
export const getEstateStats = ({
  applications = [],
  visit_spans = [],
}: GetEstateStatsProps) => {
  const visitSlots = visit_spans.flatMap((span) => span.visit_slots)

  const { nbPlannedVisits, nbDoneVisits } = (visitSlots ?? []).reduce(
    (acc, { isVisitDone }) => {
      if (!isVisitDone) {
        acc.nbPlannedVisits++
      }
      if (isVisitDone) {
        acc.nbDoneVisits++
      }
      return acc
    },
    { nbPlannedVisits: 0, nbDoneVisits: 0 }
  )

  const { nbInProgressApplications, nbReceivedApplications } =
    applications.reduce(
      (acc, { status }) => {
        if (status === Application_Status_Enum.Draft) {
          acc.nbInProgressApplications++
        }
        if (
          status === Application_Status_Enum.Submitted ||
          status === Application_Status_Enum.Accepted
        ) {
          acc.nbReceivedApplications++
        }
        return acc
      },
      { nbInProgressApplications: 0, nbReceivedApplications: 0 }
    )

  return {
    nbPlannedVisits,
    nbDoneVisits,
    nbInProgressApplications,
    nbReceivedApplications,
  }
}
