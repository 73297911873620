'use client'
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { Locale } from 'date-fns'
import { CalendarDaysIcon } from 'lucide-react'
import { Input } from '@/components/ui/input'
import { fr } from 'date-fns/locale'
import { DayPickerSingleProps } from 'react-day-picker'
import { DatePicker } from './datePicker'
import { useTranslation } from 'react-i18next'
import { formatDate, parseDate } from '@utils/dates'

interface InputDatePickerProps
  extends Omit<
    DayPickerSingleProps,
    'value' | 'disabled' | 'onSelect' | 'mode'
  > {
  placeholder?: string
  locale?: Locale
  disabled?: boolean
  value?: Date
  onChange?: (value?: Date) => void
  disabledDates?: Date[]
  className?: string
}

const InputDatePickerInner = (
  {
    placeholder,
    disabled,
    locale = fr,
    value: _value,
    onChange,
    disabledDates,
    className = 'tw-max-w-[280px]',
    ...props
  }: InputDatePickerProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const { t } = useTranslation()

  const handleChange = (value: Date) => {
    onChange?.(value)
  }

  const value = useMemo(() => {
    return parseDate(_value)
  }, [_value])

  const formattedValue = useMemo(() => {
    return formatDate(value, { locale })
  }, [value, locale])

  const [errorMessage, setErrorMessage] = useState<string>()

  const handleSelect = (selectedDate: Date | undefined) => {
    if (!selectedDate) return
    handleChange(selectedDate)
    setCurrentValue(formatDate(selectedDate, { locale }))
    setErrorMessage(undefined)
  }

  const [currentValue, setCurrentValue] = useState(formattedValue)

  useEffect(() => {
    setCurrentValue(formattedValue)
  }, [formattedValue])

  const handleInputChange = (inputValue: string) => {
    setCurrentValue(inputValue)
    if (!inputValue) return
    const dateRegex = /([0-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/([0-9]{4})/
    const match = inputValue.match(dateRegex)
    if (!match) {
      setErrorMessage(t('InputDatePicker.invalidDate'))
    }
    if (match) {
      const parsedDate = parseDate(inputValue)
      if (!parsedDate) {
        setErrorMessage(t('InputDatePicker.invalidDate'))
      }
      if (parsedDate) {
        setErrorMessage(undefined)
        handleChange(parsedDate)
      }
    }
  }

  return (
    <Popover modal>
      <div className={cn('tw-relative', className)}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            disabled={disabled}
            variant="outline"
            type="button"
            className={cn(
              'tw-font-normal tw-absolute tw-right-0 tw-translate-y-[-50%] tw-top-[50%] tw-rounded-l-none',
              !value && 'tw-text-muted-foreground'
            )}
          >
            <CalendarDaysIcon className="tw-w-4 tw-h-4" />
          </Button>
        </PopoverTrigger>
        <Input
          type="string"
          value={currentValue}
          disabled={disabled}
          onChange={(e) => {
            handleInputChange(e.target.value)
          }}
          placeholder={placeholder ?? t('InputDatePicker.placeholder')}
        />
        {errorMessage && (
          <div className="tw-absolute tw-bottom-[-1rem] tw-left-0 tw-text-red-400 tw-text-sm">
            {errorMessage}
          </div>
        )}
      </div>
      <PopoverContent align="end" className="tw-w-auto tw-p-0">
        <DatePicker
          locale={locale}
          mode="single"
          captionLayout="dropdown-buttons"
          selected={value}
          defaultMonth={value}
          disabled={disabledDates}
          onSelect={handleSelect}
          toYear={props.toYear ?? undefined}
          {...props}
        />
      </PopoverContent>
    </Popover>
  )
}

export const InputDatePicker = forwardRef(InputDatePickerInner)
