import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { ApplicationFragment, useUpdateApplicationMutation } from '@gql'
import { PlusIcon, XIcon } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

type ApplicationTagsProps = {
  application: ApplicationFragment
}

export const ApplicationTags = ({ application }: ApplicationTagsProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const isAgencyMember = useIsAgencyMember()

  const [showInput, setShowInput] = useState(false)

  const { tags } = application
  const typedTags = (tags as string[]) ?? []

  const [updateApplicationTags] = useUpdateApplicationMutation({
    refetchQueries: ['getApplicationsStatusesByEstatesIds'],
  })

  const handleAddTag = async (value: string) => {
    if (typedTags.includes(value)) return
    const newTags = [...typedTags, value]
    await updateApplicationTags({
      variables: {
        id: application.id,
        values: {
          tags: newTags,
        },
      },
      onError(error) {
        console.error(error)
        toast({
          title: t('ApplicationTags.updateApplicationTag.add.error.title'),
          description: t(
            'ApplicationTags.updateApplicationTag.add.error.description'
          ),
          variant: 'destructive',
        })
      },
    })
  }

  const handleRemove = async (value: string) => {
    if (typedTags.includes(value)) {
      const newTags = typedTags.filter((tag) => tag !== value)

      await updateApplicationTags({
        variables: {
          id: application.id,
          values: {
            tags: newTags,
          },
        },
        onError(error) {
          console.error(error)
          toast({
            title: t('ApplicationTags.updateApplicationTag.delete.error.title'),
            description: t(
              'ApplicationTags.updateApplicationTag.delete.error.description'
            ),
            variant: 'destructive',
          })
        },
      })
    }
  }

  return (
    <div className="tw-flex tw-flex-col tw-items-end tw-gap-1">
      <div className="tw-flex tw-flex-row tw-flex-wrap tw-justify-end tw-gap-1 tw-items-center">
        {typedTags.length > 0 &&
          typedTags.map((tag) => (
            <Badge key={tag} variant="secondary" className="tw-group">
              <span className="tw-text-sm">{tag}</span>
              <div className="tw-h-4 tw-w-4 tw-ml-1">
                <XIcon
                  className="tw-hidden tw-h-4 tw-w-4 tw-cursor-pointer group-hover:tw-block group-hover:tw-bg-zinc-300 group-hover:tw-rounded-full group-hover:tw-p-0.5"
                  onClick={() => handleRemove(tag)}
                />
              </div>
            </Badge>
          ))}
      </div>
      {isAgencyMember && (
        <>
          {!showInput && (
            <Button
              variant="ghost"
              size="icon"
              className="tw-w-6 tw-h-6 tw-rounded-full hover:tw-bg-muted"
              onClick={() => setShowInput((prev) => !prev)}
            >
              <PlusIcon className="tw-w-4 tw-h-4" />
            </Button>
          )}
          {showInput && (
            <div className="tw-flex tw-items-center">
              <Input
                type="text"
                placeholder="Libellé de votre tag"
                className="tw-h-5"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleAddTag(e.currentTarget.value)
                    setShowInput(false)
                  }
                }}
              />
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setShowInput(false)}
              >
                <XIcon className="tw-h-4 tw-w-4" />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}
