import { StarScore } from '@/common/atoms/StarScore'
import { useToast } from '@/components/ui/use-toast'
import { ApplicationFragment, useUpdateApplicationMutation } from '@gql'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ApplicationsStarScoreRowProps = {
  application: ApplicationFragment
}
export const ApplicationsStarScoreRow = ({
  application: { score, id },
}: ApplicationsStarScoreRowProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [updateApplicationScore] = useUpdateApplicationMutation({
    refetchQueries: ['getApplicationsStatusesByEstatesIds'],
  })

  const changeScore = async (score: number) => {
    await updateApplicationScore({
      variables: { id, values: { score } },
      onCompleted: () => {
        toast({
          title: t(
            'ApplicationsStarScoreRow.updateApplicationScore.success.title'
          ),
          description: t(
            'ApplicationsStarScoreRow.updateApplicationScore.success.description'
          ),
          variant: 'success',
        })
      },
      onError: (error) => {
        console.error(error)
        toast({
          title: t(
            'ApplicationsStarScoreRow.updateApplicationScore.error.title'
          ),
          description: t(
            'ApplicationsStarScoreRow.updateApplicationScore.error.description'
          ),
          variant: 'destructive',
        })
      },
    })
  }

  return <StarScore value={score} onChange={(value) => changeScore(value)} />
}
