import { Card } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { useToast } from '@/components/ui/use-toast'
import { EstateForMainPageFragment, useUpdateEstateMutation } from '@gql'
import debounce from 'lodash.debounce'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EstateConditionAndServicesForm } from './EstateConditionAndServicesForm'
import { EstateCoownershipForm } from './EstateCoownershipForm'
import { EstateFeaturesForm } from './EstateFeaturesForm'
import { EstateIdentityAndAddressForm } from './EstateIdentityAndAddressForm'
import { EstateTypeForm } from './EstateTypeForm'

export type EstateEditFormProps = {
  estate: Pick<
    EstateForMainPageFragment,
    | 'id'
    | 'type'
    | 'name'
    | 'address'
    | 'zipCode'
    | 'city'
    | 'features'
    | 'servicesAndConditions'
    | 'coownership'
  >
}
export const EstateEditForm = ({ estate }: EstateEditFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [
    updateEstate,
    {
      data: updateEstateData,
      // loading: updateEstateLoading,
      error: updateEstateError,
    },
  ] = useUpdateEstateMutation({
    refetchQueries: ['getEstates'],
  })

  useEffect(() => {
    if (updateEstateData) {
      toast({
        variant: 'success',
        description: t('EstateDiagnosticsTab.toast.success'),
      })
    }
  }, [updateEstateData])

  useEffect(() => {
    if (updateEstateError) {
      toast({
        variant: 'destructive',
        description: t('EstateDiagnosticsTab.toast.error'),
      })
    }
  }, [updateEstateError])

  const debouncedUpdateEstate = useMemo(
    () =>
      debounce((values: Partial<EstateForMainPageFragment>) => {
        updateEstate({
          variables: {
            id: estate.id,
            values,
          },
        })
      }, 500),
    [estate.id, updateEstate]
  )

  // TODO: Check if there is a cleaner way (without key) to force re-render
  return (
    <div className="tw-space-y-4" key={estate.id}>
      <>
        <h2 className="tw-text-2xl tw-font-semibold">
          {t('EstatePage.identityAndAddress')}
        </h2>
        <Card className="tw-p-4">
          <EstateIdentityAndAddressForm
            estate={estate}
            onSubmit={debouncedUpdateEstate}
          />
        </Card>
      </>
      <Separator className="tw-my-6" />
      <>
        <h2 className="tw-text-2xl tw-font-semibold">
          {t('EstatePage.features')}
        </h2>
        <Card className="tw-p-4">
          <EstateTypeForm estate={estate} onSubmit={debouncedUpdateEstate} />
          <EstateFeaturesForm
            estate={estate}
            onSubmit={debouncedUpdateEstate}
          />
        </Card>
      </>
      <Separator className="tw-my-6" />
      <>
        <h2 className="tw-text-2xl tw-font-semibold">
          {t('EstatePage.conditionAndServices')}
        </h2>
        <Card className="tw-p-4">
          <EstateConditionAndServicesForm
            estate={estate}
            onSubmit={debouncedUpdateEstate}
          />
        </Card>
      </>
      <Separator className="tw-my-6" />
      <>
        <h2 className="tw-text-2xl tw-font-semibold">
          {t('EstatePage.coownership')}
        </h2>
        <Card className="tw-p-4">
          <EstateCoownershipForm
            estate={estate}
            onSubmit={debouncedUpdateEstate}
          />
        </Card>
      </>
    </div>
  )
}
