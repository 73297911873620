import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useSellerAddEstateSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().optional(),
        address: yup
          .string()
          .min(2, t('min', { min: 2 }))
          .required(t('required')),
        city: yup
          .string()
          .min(2, t('min', { min: 2 }))
          .required(t('required')),
        zipCode: yup
          .string()
          .min(2, t('min', { min: 2 }))
          .required(t('required')),
      }),
    [t]
  )

  return { schema }
}

export type EstateAddFormValues = yup.InferType<
  ReturnType<typeof useSellerAddEstateSchema>['schema']
>
