import { Button } from '@/components/ui/button'
import { EstateMediaTypeFragment } from '@gql'
import { FileTextIcon } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EstateMediaFileUploadDialog } from './EstateMediaFileUploadDialog'
import { EstateMediaTypeNameTranslation } from './EstateMediaTypeNameTranslation'

export type MissingEstateMediaProps = {
  estateId: string
  type: EstateMediaTypeFragment
}
export const MissingEstateMedia = ({
  estateId,
  type,
}: MissingEstateMediaProps) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="tw-flex tw-flex-row tw-gap-4 tw-items-center">
      <div className="tw-bg-gray-100 tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-lg">
        <FileTextIcon />
      </div>
      <div className="tw-flex-grow">
        <EstateMediaTypeNameTranslation type={type} />
      </div>
      <Button variant="outline" onClick={() => setIsOpen(true)}>
        {t('common.add')}
      </Button>
      <EstateMediaFileUploadDialog
        estateId={estateId}
        showToasts={true}
        open={isOpen}
        onOpenChange={() => setIsOpen(false)}
        type={type}
        onUpload={() => setIsOpen(false)}
        canSetCustomName={true}
      />
    </div>
  )
}
