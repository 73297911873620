import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { EstateMediasPhotosTab } from '@/estateMedia/components/EstateMedias/EstateMediasPhotosTab'
import { EstateMediasVideosTab } from '@/estateMedia/components/EstateMedias/EstateMediasVideosTab'
import { EstateMediasVirtualVisitTab } from '@/estateMedia/components/EstateMedias/EstateMediasVirtualVisitTab'
import { useGetEstateForMediasPageQuery } from '@gql'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { EstateNotFoundError } from '../utils/EstateNotFoundError'

export const EstateMediasPage = () => {
  const { t } = useTranslation()
  const { estateId } = useParams<{ estateId: string }>()

  const { loading, error, data } = useGetEstateForMediasPageQuery({
    variables: {
      id: estateId!,
    },
    skip: estateId == null,
    fetchPolicy: 'cache-and-network',
  })
  const estate = useMemo(() => data?.estate_by_pk, [data])

  return (
    <div className="tw-py-6 tw-px-8">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('EstateMediasPage.title')}
      </h1>
      <div className="tw-py-4 md:tw-py-6">
        {data == null && loading ? (
          <Loading active />
        ) : error ? (
          <TextError error={error} />
        ) : estate == null ? (
          <TextError error={new EstateNotFoundError(t)} />
        ) : (
          <>
            <Tabs defaultValue="photos" className="tw-w-[650px]">
              <TabsList>
                <TabsTrigger value="photos">
                  {t('EstateMediasPage.photos')}
                </TabsTrigger>
                <TabsTrigger value="virtualVisit">
                  {t('EstateMediasPage.virtualVisit')}
                </TabsTrigger>
                <TabsTrigger value="video">
                  {t('EstateMediasPage.video')}
                </TabsTrigger>
              </TabsList>
              <TabsContent value="photos">
                <EstateMediasPhotosTab estateId={estate.id} />
              </TabsContent>
              <TabsContent value="virtualVisit">
                <EstateMediasVirtualVisitTab estate={estate} />
              </TabsContent>
              <TabsContent value="video">
                <EstateMediasVideosTab estateId={estate.id} />
              </TabsContent>
            </Tabs>
          </>
        )}
      </div>
    </div>
  )
}
