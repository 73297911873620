import { toast } from '@/components/ui/use-toast'
import { useCreateEstateMedia } from '@/estateMedia/hooks/useCreateEstateMedia'
import {
  MultipleFileUploadDialog,
  MultipleFileUploadDialogProps,
} from '@/file/MultipleFileUploadDialog'
import { EstateMediaTypeFragment } from '@gql'
import React, { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { EstateMediaTypeNameTranslation } from './EstateMediaTypeNameTranslation'

export type EstateMediaMultipleFileUploadDialogProps = Omit<
  MultipleFileUploadDialogProps,
  'onFileUpload' | 'onDone'
> & {
  children?: ReactNode
  onUpload: () => void
  type: EstateMediaTypeFragment
  estateId: string
  showToasts?: boolean
}
export const EstateMediaMultipleFileUploadDialog = ({
  children,
  onUpload,
  type,
  estateId,
  showToasts,
  ...props
}: EstateMediaMultipleFileUploadDialogProps) => {
  const { t } = useTranslation()
  const { createEstateMedia, data, loading, error } = useCreateEstateMedia()

  const handleFileUpload = async (id: string) => {
    if (!id) {
      toast({
        variant: 'destructive',
        description: t('EstateMediaFileUploadDialog.toast.error'),
      })
      return
    }
    await createEstateMedia({
      variables: {
        values: {
          fileId: id,
          typeId: type.id,
          estateId: estateId,
        },
      },
    })
  }

  useEffect(() => {
    if (showToasts && error) {
      toast({
        variant: 'destructive',
        description: t('EstateMediaMultipleFileUploadDialog.toast.error'),
      })
    }
  }, [error])

  useEffect(() => {
    if (showToasts && data) {
      toast({
        variant: 'success',
        description: t('EstateMediaMultipleFileUploadDialog.toast.success'),
      })
    }
  }, [data])

  return (
    <MultipleFileUploadDialog
      description={<EstateMediaTypeNameTranslation type={type} />}
      onFileUpload={handleFileUpload}
      onDone={onUpload}
      loading={loading}
      {...props}
    >
      {children}
    </MultipleFileUploadDialog>
  )
}
