import Page404 from '@/common/pages/Page404'
import { EstateDocumentsPage } from '@/estate/pages/EstateDocumentsPage'
import { EstateMediasPage } from '@/estate/pages/EstateMediasPage'
import { EstatePage } from '@/estate/pages/EstatePage'
import { EstateSalePage } from '@/estate/pages/EstateSalePage'
import { EstateSellersPage } from '@/estate/pages/EstateSellersPage'
import { EstateLayout } from '@/layout/EstateLayout'
import { MandatePage } from '@/mandate/pages/MandatePage'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

export const EstateRoute = () => {
  return (
    <Routes>
      <Route element={<EstateLayout />}>
        <Route index element={<EstatePage />} />

        <Route path="/sellers" element={<EstateSellersPage />} />
        <Route path="/files" element={<EstateDocumentsPage />} />
        <Route path="/medias" element={<EstateMediasPage />} />
        <Route path="/mandate" element={<MandatePage />} />
        <Route path="/sale" element={<EstateSalePage />} />

        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  )
}
