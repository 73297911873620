import React, { useState } from 'react'
import { GetEstateVisitSpansQuery, useDeleteVisitSlotMutation } from '@gql'
import { Button } from '@/components/ui/button'
import { TrashIcon } from 'lucide-react'
import { useToast } from '@/components/ui/use-toast'
import { useTranslation } from 'react-i18next'
import { ConfirmationDialog } from '@/components/ui/ConfirmationDialog'

type EstateVisitDeleteButtonProps = {
  visitSlot: GetEstateVisitSpansQuery['visit_span'][number]['visit_slots'][number]
  onDelete: () => void
}

export const EstateVisitDeleteButton = ({
  visitSlot,
  onDelete,
}: EstateVisitDeleteButtonProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [exists, setExists] = useState(!!visitSlot)
  const [forceRefreshKey, setForceRefreshKey] = useState(0)

  const [deleteVisitSlot, { loading }] = useDeleteVisitSlotMutation({
    variables: {
      id: visitSlot.id,
    },
    refetchQueries: ['getEstateVisitSpans'],
  })

  const handleDeleteSlot = async () => {
    // Optimistic update
    setExists(false)
    setForceRefreshKey(forceRefreshKey + 1)
    const response = await deleteVisitSlot({
      onCompleted: () => {
        toast({
          description: t('EstateVisitDeleteButton.toast.success'),
          variant: 'success',
        })
        onDelete()
      },
      onError(error) {
        console.error(error)
        toast({
          description: t('EstateVisitDeleteButton.toast.error'),
          variant: 'destructive',
        })
      },
    })
    if (response?.data?.delete_visit_slot_by_pk?.id) {
      setExists(false)
      setForceRefreshKey(forceRefreshKey + 1)
    }
  }
  const [confirmationPendingCallback, setConfirmationPendingCallback] =
    useState<() => Promise<void> | void>()

  const handleDeleteClick = () => {
    setConfirmationPendingCallback(() => () => {
      handleDeleteSlot()
    })
  }

  if (!exists) return null

  return (
    <div
      key={forceRefreshKey}
      className="tw-flex tw-justify-between tw-grow tw-content-center"
    >
      <Button
        variant="destructive"
        onClick={handleDeleteClick}
        disabled={loading}
      >
        <TrashIcon className="tw-h-5 tw-w-5" />
      </Button>
      <ConfirmationDialog
        open={!!confirmationPendingCallback}
        onOpenChange={() => setConfirmationPendingCallback(undefined)}
        onConfirm={confirmationPendingCallback}
      />
    </div>
  )
}
