import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { ConfirmationDialog } from '@/components/ui/ConfirmationDialog'
import { useGetPresignedUrl } from '@/file/hooks/useGetPresignedUrl'
import { VideoThumbnail } from '@/file/video/VideoThumbnail'
import { FileSize } from '@almaris/shared/helpers/FileSize'
import { useDeleteEstateMediaMutation, WebappEstateMediaFragment } from '@gql'
import { FileTextIcon, ImageIcon } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { CensoredEstateMedia } from './CensoredEstateMedia'
import { EstateMediaFileUploadDialog } from './EstateMediaFileUploadDialog'
import { EstateMediaMenu } from './EstateMediaMenu'
import { EstateMediaTypeNameTranslation } from './EstateMediaTypeNameTranslation'
import { MissingCensoredEstateMedia } from './MissingCensoredEstateMedia'

export type EstateMediaProps = {
  estateId: string
  estateMedia: WebappEstateMediaFragment
  canSetCustomName?: boolean
  variant?: 'default' | 'photo' | 'video'
}
export const EstateMedia = ({
  estateId,
  estateMedia,
  canSetCustomName,
  variant = 'default',
}: EstateMediaProps) => {
  const { type, file, name, censoredVersion, id } = estateMedia
  const [confirmationPendingCallback, setConfirmationPendingCallback] =
    useState<() => Promise<void> | void>()
  const [uploadPendingCallback, setUploadPendingCallback] =
    useState<() => Promise<void> | void>()

  const [deleteEstateMediaMutation, { loading: deleteEstateMediaLoading }] =
    useDeleteEstateMediaMutation({
      variables: { id },
      onError: (error) => {
        console.error(error)
      },
      refetchQueries: ['getEstateMedia'],
    })

  const { url, error, loading } = useGetPresignedUrl({
    fileId: file.id,
    skip: !file.id || deleteEstateMediaLoading,
  })

  const Icon = useMemo(() => {
    switch (file.mimeType) {
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/png':
        return ImageIcon
      default:
        return FileTextIcon
    }
  }, [file.mimeType])

  const handleDeleteClick = () =>
    setConfirmationPendingCallback(() => async () => {
      deleteEstateMediaMutation()
    })

  const handleEditClick = () => {
    setUploadPendingCallback(() => () => {
      deleteEstateMediaMutation()
      setUploadPendingCallback(undefined)
    })
  }

  const defaultRender = () => {
    return (
      <div className="tw-w-full">
        <div className="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-items-center">
          <div className="tw-w-full tw-flex tw-flex-row tw-gap-4 tw-items-center">
            <Link
              className="tw-text-zinc-900 tw-font-medium tw-flex-grow tw-flex tw-items-center tw-gap-4"
              to={url || '#'}
              target="_blank"
            >
              <div className="tw-bg-gray-100 tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-lg">
                <Icon />
              </div>
              <div>
                {name || (
                  <EstateMediaTypeNameTranslation
                    type={type}
                    defaultValue={file.name || undefined}
                  />
                )}
                <div className="tw-text-zinc-500 tw-font-normal">
                  <FileSize size={file.size || 0} /> · {file.mimeType}
                </div>
              </div>
            </Link>
            <EstateMediaMenu
              onDelete={handleDeleteClick}
              onEdit={handleEditClick}
            />
          </div>
        </div>
        {type.hasCensoredVersion && (
          <div className="tw-ml-12 tw-mt-2">
            {!!censoredVersion && (
              <CensoredEstateMedia censoredVersion={censoredVersion} />
            )}
            {!censoredVersion && (
              <MissingCensoredEstateMedia estateMedia={estateMedia} />
            )}
          </div>
        )}
      </div>
    )
  }

  const photoRender = () => {
    return (
      <div className="tw-flex tw-flex-row tw-gap-4 tw-items-center tw-w-[180px] tw-h-[180px] tw-relative tw-bg-gray-100 tw-rounded-lg tw-overflow-hidden">
        <Link
          className="tw-text-zinc-900 tw-font-medium tw-flex-grow tw-flex tw-items-center tw-gap-4 tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full"
          to={url || '#'}
          target="_blank"
        >
          <img
            className="tw-object-cover tw-w-full tw-h-full"
            src={url}
            alt={name ?? url}
          />
        </Link>
        <EstateMediaMenu
          className="tw-absolute tw-top-1 tw-right-1"
          onDelete={handleDeleteClick}
        />
      </div>
    )
  }

  const videoRender = () => {
    return (
      <div className="tw-flex tw-flex-row tw-gap-4 tw-items-center tw-w-[180px] tw-h-[180px] tw-relative tw-bg-gray-100 tw-rounded-lg tw-overflow-hidden">
        <Link
          className="tw-text-zinc-900 tw-font-medium tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full"
          to={url || '#'}
          target="_blank"
        >
          <VideoThumbnail url={url} />
        </Link>
        <EstateMediaMenu
          className="tw-absolute tw-top-1 tw-right-1"
          onDelete={handleDeleteClick}
        />
      </div>
    )
  }

  if (loading || deleteEstateMediaLoading) return <Loading active />
  if (error) return <TextError error={error} />
  return (
    <>
      {variant === 'default' && defaultRender()}
      {variant === 'photo' && photoRender()}
      {variant === 'video' && videoRender()}
      <EstateMediaFileUploadDialog
        estateId={estateId}
        showToasts={true}
        open={!!uploadPendingCallback}
        onOpenChange={() => setUploadPendingCallback(undefined)}
        type={type}
        onUpload={uploadPendingCallback}
        canSetCustomName={canSetCustomName}
        customName={name || undefined}
      />

      <ConfirmationDialog
        open={!!confirmationPendingCallback}
        onOpenChange={() => setConfirmationPendingCallback(undefined)}
        onConfirm={confirmationPendingCallback}
      />
    </>
  )
}
