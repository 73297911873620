import { Application_Status_Enum } from '@gql'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EstateOfferAcceptedUpload } from './EstateOfferAcceptedUpload'

type EstateConfirmDialogContentProps = {
  estate?: {
    id: string
    isPublished: boolean
    archived: boolean
    applications: { status: Application_Status_Enum }[]
  }
}

// TODO fix this since this should only be text
const EstateConfirmDialogContent = ({
  estate,
}: EstateConfirmDialogContentProps) => {
  const { t } = useTranslation()

  const isOfferAccepted = useMemo(() => {
    if (estate == null) return false
    return estate.applications.some(
      (application) => application.status === Application_Status_Enum.Accepted
    )
  }, [estate])

  if (!estate) return null

  return (
    <>
      {!estate.isPublished && isOfferAccepted ? (
        <EstateOfferAcceptedUpload estate={estate} />
      ) : (
        <>
          {t('EstateConfirmDialogContent.ongoing.description.title')}
          <br />
          <br />
          {t('EstateConfirmDialogContent.ongoing.description.content')}
          <br />
          <br />
          {t('EstateConfirmDialogContent.ongoing.description.confirm')}
        </>
      )}
    </>
  )
}

export default EstateConfirmDialogContent
