import React, { useState } from 'react'
import {
  GetEstateVisitSpansQuery,
  useUpdateVisitSlotIsVisitDoneMutation,
} from '@gql'
import { Button } from '@/components/ui/button'
import { CheckIcon } from 'lucide-react'
import { useToast } from '@/components/ui/use-toast'
import { useTranslation } from 'react-i18next'

type EstateVisitDoneButtonProps = {
  visitSlot: GetEstateVisitSpansQuery['visit_span'][number]['visit_slots'][number]
  onUpdate: (isVisitDone: boolean) => void
  readOnly?: boolean
}

export const EstateVisitDoneButton = ({
  visitSlot,
  onUpdate,
  readOnly,
}: EstateVisitDoneButtonProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [isVisitDone, setIsVisitDone] = useState(visitSlot.isVisitDone)
  const [forceRefreshKey, setForceRefreshKey] = useState(0)

  const [updateVisitSlotIsVisitDone, { loading }] =
    useUpdateVisitSlotIsVisitDoneMutation()

  const handleUpdateSlot = async (newIsVisitDone: boolean) => {
    if (readOnly) return
    // Optimistic update
    setIsVisitDone(newIsVisitDone)
    setForceRefreshKey(forceRefreshKey + 1)
    const response = await updateVisitSlotIsVisitDone({
      variables: {
        id: visitSlot.id,
        isVisitDone: newIsVisitDone,
      },
      onCompleted: () => {
        toast({
          description: t('EstateVisitDoneButton.toast.success'),
          variant: 'success',
        })
        onUpdate(newIsVisitDone)
      },
      onError(error) {
        console.error(error)
        toast({
          description: t('EstateVisitDoneButton.toast.error'),
          variant: 'destructive',
        })
      },
    })
    // Response update
    const responseIsVisitDone =
      response?.data?.update_visit_slot_by_pk?.isVisitDone
    if (responseIsVisitDone !== isVisitDone) {
      setIsVisitDone(!!responseIsVisitDone)
      setForceRefreshKey(forceRefreshKey + 1)
    }
  }
  return (
    <div
      key={forceRefreshKey}
      className="tw-flex tw-justify-between tw-grow tw-content-center"
    >
      {isVisitDone ? (
        <Button
          size="sm"
          onClick={() => handleUpdateSlot(false)}
          disabled={loading || readOnly}
        >
          <CheckIcon className="tw-h-5 tw-w-5" />
          <span className="tw-ml-1">
            {t('EstateVisitDoneButton.button.undone')}
          </span>
        </Button>
      ) : (
        <Button
          size="sm"
          variant="outline"
          onClick={() => handleUpdateSlot(true)}
          disabled={loading || readOnly}
        >
          {t('EstateVisitDoneButton.button.done')}
        </Button>
      )}
    </div>
  )
}
