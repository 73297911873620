import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Progress } from '@/components/ui/progress'
import { useToast } from '@/components/ui/use-toast'
import { useEstateMediaTypes } from '@/estate/hooks/useEstateMediaTypes'
import { useCreateEstateMedia } from '@/estateMedia/hooks/useCreateEstateMedia'
import { useEstateMedias } from '@/estateMedia/hooks/useEstateMedias'
import { useGetPresignedUrl } from '@/file/hooks/useGetPresignedUrl'
import {
  Estate_Media_Type_Group_Enum,
  Estate_Media_Type_Name_Enum,
  WebappEstateMediaFragment,
  useDeleteEstateMediaMutation,
} from '@gql'
import { useFileUpload } from '@nhost/react'
import { FileTextIcon, ForwardIcon, Trash2Icon } from 'lucide-react'
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

type EstateOfferAcceptedUploadProps = {
  estate: {
    id: string
  }
}

export const EstateOfferAcceptedUpload = ({
  estate,
}: EstateOfferAcceptedUploadProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const { createEstateMedia } = useCreateEstateMedia()
  const { estateMedias, loading } = useEstateMedias({
    estateId: estate.id,
    typeGroup: Estate_Media_Type_Group_Enum.Estate,
  })

  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id,
    name,
  } = useFileUpload()
  const [isFileSet, setIsFileSet] = useState<File | undefined>(undefined)

  const { estateMediaTypes } = useEstateMediaTypes({
    group: Estate_Media_Type_Group_Enum.Estate,
  })

  const estateMediaTypeAuthenticatedDeed = useMemo(() => {
    return estateMediaTypes.find(
      (type) => type.name === Estate_Media_Type_Name_Enum.AuthenticatedDeed
    )
  }, [estateMediaTypes])

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return
    add({ file })
    setIsFileSet(file)
  }

  const handleSubmit = useCallback(async () => {
    if (!isFileSet) return
    await upload({ file: isFileSet })
    setIsFileSet(undefined)
  }, [isFileSet])

  const handleCancel = useCallback(() => {
    cancel()
  }, [cancel])

  useEffect(() => {
    async function handleCreateEstateMedia() {
      await createEstateMedia({
        variables: {
          values: {
            fileId: id,
            typeId: estateMediaTypeAuthenticatedDeed?.id,
            name: name,
            estateId: estate.id,
          },
        },
      })
    }
    if (isUploaded) {
      handleCreateEstateMedia()
      toast({
        variant: 'success',
        description: t('FileUpload.toast.success'),
      })
    }
  }, [isUploaded])

  useEffect(() => {
    if (isError) {
      toast({
        variant: 'destructive',
        description: t('FileUpload.toast.error'),
      })
    }
  }, [isError])

  return (
    <div className="tw-space-y-4">
      <p>{t('EstateOfferAcceptedUpload.description')}</p>
      {loading && <Loading center active />}
      <EstateOfferAcceptedUploadPreview estateMedias={estateMedias} />
      {isUploading ? (
        <div>
          <Progress value={progress} />
          <Button variant="destructive" onClick={handleCancel}>
            {t('common.cancel')}
          </Button>
        </div>
      ) : (
        <div className="tw-flex tw-gap-4">
          <Input type="file" multiple={false} onChange={handleFileChange} />
          <Button
            size="icon"
            type="button"
            variant="secondary"
            onClick={handleSubmit}
            disabled={!isFileSet}
          >
            <ForwardIcon className="tw-h-4 tw-w-4" />
          </Button>
        </div>
      )}
      <p>{t('EstateOfferAcceptedUpload.subdescription')}</p>
    </div>
  )
}

type EstateOfferAcceptedUploadPreviewProps = {
  estateMedias?: WebappEstateMediaFragment[]
}
const EstateOfferAcceptedUploadPreview = ({
  estateMedias,
}: EstateOfferAcceptedUploadPreviewProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [deleteMedia] = useDeleteEstateMediaMutation()

  const handleDeleteMedia = async (id: string) => {
    await deleteMedia({
      variables: {
        id,
      },
      onCompleted: () => {
        toast({
          title: t(
            'EstateOfferAcceptedUploadPreview.deleteMedia.success.title'
          ),
          description: t(
            'EstateOfferAcceptedUploadPreview.deleteMedia.success.description'
          ),
          variant: 'success',
        })
      },
      onError: (error) => {
        console.error(error)
        toast({
          title: t('EstateOfferAcceptedUploadPreview.deleteMedia.error.title'),
          description: t(
            'EstateOfferAcceptedUploadPreview.deleteMedia.error.description'
          ),
          variant: 'destructive',
        })
      },
    })
  }

  const currentEstateMedia = estateMedias?.find(
    (media) => media.type.name === Estate_Media_Type_Name_Enum.AuthenticatedDeed
  )

  const { url, error, loading } = useGetPresignedUrl({
    fileId: String(currentEstateMedia?.fileId),
    skip: !currentEstateMedia?.fileId,
  })
  if (!currentEstateMedia) return null
  if (loading) return <Loading active />
  if (error) return <TextError error={error} />

  return (
    <div className="tw-flex tw-flex-row tw-gap-4 tw-items-center">
      <Link
        className="tw-text-zinc-900 tw-font-medium tw-flex-grow tw-flex tw-items-center tw-gap-4"
        to={url || '#'}
        target="_blank"
      >
        <div className="tw-bg-gray-100 tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded-lg">
          <FileTextIcon />
        </div>
        <p className="tw-text-sm">{currentEstateMedia.file?.name || ''}</p>
      </Link>
      <Button
        size="icon"
        type="button"
        variant="secondary"
        onClick={() => handleDeleteMedia(currentEstateMedia.id)}
      >
        <Trash2Icon className="tw-h-4 tw-w-4" />
      </Button>
    </div>
  )
}
