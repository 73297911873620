import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { ConfirmationDialog } from '@/components/ui/ConfirmationDialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useToast } from '@/components/ui/use-toast'
import {
  SellerMemberFragment,
  useDeleteSellerMemberMutation,
  useDeleteUserMutation,
} from '@gql'
import { useUserData } from '@nhost/react'
import { getInitials } from '@utils/getInitials'
import { Ellipsis, Mails, Trash } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { trpc } from 'src/trpc'

export default function MemberItem(member: SellerMemberFragment) {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [confirmationPendingCallback, setConfirmationPendingCallback] =
    useState<() => Promise<void> | void>()
  const isAgencyMember = useIsAgencyMember()

  const currentMember = useUserData()
  const [deleteUser] = useDeleteUserMutation()
  const [deleteSellerMember] = useDeleteSellerMemberMutation()

  const handleResendInvite = async () => {
    try {
      if (!member.inviteEmail) {
        throw new Error('Email is required')
      }
      await trpc.member.inviteMember.mutate({
        memberId: String(member.id),
        email: String(member.inviteEmail),
        inviterMember: currentMember?.displayName ?? currentMember?.email ?? '',
      })
      toast({
        title: t('MemberItem.inviteMember.success.title', {
          member: `${member.firstName} ${member.lastName}`,
        }),
        description: t('MemberItem.inviteMember.success.description'),
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      toast({
        title: t('MemberItem.inviteMember.error.title'),
        description: t('MemberItem.inviteMember.error.description'),
        variant: 'destructive',
      })
    }
  }

  const handleDelete = async () => {
    if (!member.userId) return
    await deleteUser({
      variables: {
        id: member.userId,
      },
      onCompleted: () => {
        toast({
          title: t('MemberItem.deleteUser.success.title'),
          description: t('MemberItem.deleteUser.success.description'),
          variant: 'success',
        })
      },
      onError: (error) => {
        console.error(error)
        toast({
          title: t('MemberItem.deleteUser.error.title'),
          description: t('MemberItem.deleteUser.error.description'),
          variant: 'destructive',
        })
      },
    })
  }

  const handleRevokeInvite = async () => {
    if (!member.id) return
    await deleteSellerMember({
      variables: {
        id: member.id,
      },
      onCompleted: () => {
        toast({
          title: t('MemberItem.deleteSellerMember.success.title'),
          description: t('MemberItem.deleteSellerMember.success.description'),
          variant: 'success',
        })
      },
      onError: (error) => {
        console.error(error)
        toast({
          title: t('MemberItem.deleteSellerMember.error.title'),
          description: t('MemberItem.deleteSellerMember.error.description'),
          variant: 'destructive',
        })
      },
    })
  }

  return (
    <div className="tw-p-2 tw-flex tw-items-center">
      <div className="tw-flex tw-items-center tw-flex-grow">
        <Avatar className="tw-flex tw-h-8 tw-w-8 tw-border">
          <AvatarFallback>
            {getInitials(`${member.firstName} ${member.lastName}`)}
          </AvatarFallback>
        </Avatar>
        <div className="tw-ml-4">
          <p className="tw-font-medium">
            {member.firstName} {member.lastName}
          </p>
          <p>{member.inviteEmail}</p>
        </div>
      </div>
      <div className="tw-space-x-2">
        {!member.userId && <Badge>{t('common.badge.invited')}</Badge>}
        {member.job && <Badge variant="outline">{member.job}</Badge>}
        {member.isMainContact && (
          <Badge variant="secondary">{t('MemberItem.badge.mainContact')}</Badge>
        )}
      </div>
      {isAgencyMember && (
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="tw-ml-2">
              <Ellipsis />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="tw-w-56">
            {!member.userId && (
              <>
                <DropdownMenuItem
                  className="tw-flex tw-items-center tw-gap-2"
                  onClick={handleResendInvite}
                >
                  <Mails size="16" />
                  {t('MemberItem.actions.resendInvite')}
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="tw-flex tw-items-center tw-gap-2"
                  onClick={() =>
                    setConfirmationPendingCallback(() => handleRevokeInvite)
                  }
                >
                  <Trash size="16" />
                  {t('MemberItem.actions.revokeInvite')}
                </DropdownMenuItem>
              </>
            )}
            {member.userId && (
              <DropdownMenuItem
                className="tw-flex tw-items-center tw-gap-2"
                onClick={() =>
                  setConfirmationPendingCallback(() => handleDelete)
                }
              >
                <Trash size="16" />
                {t('MemberItem.actions.delete')}
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      <ConfirmationDialog
        open={!!confirmationPendingCallback}
        onOpenChange={() => setConfirmationPendingCallback(undefined)}
        onConfirm={confirmationPendingCallback}
      />
    </div>
  )
}
