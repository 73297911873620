import React from 'react'
import { StarScore } from '@/common/atoms/StarScore'
import {
  useGetApplicationScoreQuery,
  useUpdateApplicationScoreMutation,
} from '@gql'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/components/ui/use-toast'

export type EstateBuyerMemberScoreProps = {
  estateId: string
  buyerMemberId: string
}

export const EstateBuyerMemberScore = ({
  estateId,
  buyerMemberId,
}: EstateBuyerMemberScoreProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const variables = {
    estateId,
    buyerMemberId,
  }
  const { data, loading, error } = useGetApplicationScoreQuery({
    variables,
    onCompleted: (data) => {
      if (!data?.application.length) {
        console.error('Error: No application found')
        toast({
          title: t('EstateBuyerMemberScore.get.toast.error'),
          description: t('EstateBuyerMemberScore.get.toast.errorDesc'),
          variant: 'success',
        })
      }
    },
    onError: (error) => {
      console.error(error)
      toast({
        title: t('EstateBuyerMemberScore.get.toast.error'),
        description: t('EstateBuyerMemberScore.get.toast.errorDesc'),
        variant: 'destructive',
      })
    },
  })

  const [update, { loading: updateLoading }] =
    useUpdateApplicationScoreMutation({
      refetchQueries: ['getApplicationScore'],
      onCompleted: (data) => {
        if (data?.update_application_by_pk?.id) {
          toast({
            title: t('EstateBuyerMemberScore.update.toast.success'),
            description: t('EstateBuyerMemberScore.update.toast.successDesc'),
            variant: 'success',
          })
        } else {
          console.error(error)
          toast({
            title: t('EstateBuyerMemberScore.update.toast.error'),
            description: t('EstateBuyerMemberScore.update.toast.errorDesc'),
            variant: 'destructive',
          })
        }
      },
      onError: (error) => {
        console.error(error)
        toast({
          title: t('EstateBuyerMemberScore.update.toast.error'),
          description: t('EstateBuyerMemberScore.update.toast.errorDesc'),
          variant: 'destructive',
        })
      },
    })

  const application = data?.application[0]

  const value = application?.score ?? 0
  const id = application?.id
  const isLoading = loading || updateLoading

  const handleChange = (score: number) => {
    if (!id) return
    update({
      variables: {
        id,
        score,
      },
    })
  }

  return (
    <StarScore value={value} onChange={handleChange} disabled={isLoading} />
  )
}
