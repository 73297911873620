import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { useGetEstateForMainPageQuery } from '@gql'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { EstateEditForm } from '../components/Estate/EstateEditForm'
import { EstateEstimatePriceCard } from '../components/Estate/EstateEstimatePriceCard'
import { EstateNotFoundError } from '../utils/EstateNotFoundError'

export const EstatePage = () => {
  const { t } = useTranslation()
  const { estateId } = useParams<{ estateId: string }>()

  const { loading, error, data, refetch } = useGetEstateForMainPageQuery({
    variables: {
      id: estateId!,
    },
    skip: estateId == null,
    fetchPolicy: 'cache-and-network',
  })
  const estate = useMemo(() => data?.estate_by_pk, [data])

  return (
    <div className="tw-px-8 tw-py-6">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('EstatePage.title')}
      </h1>
      <div className="tw-w-full tw-grid md:tw-grid-cols-[1fr_40%] tw-gap-6 tw-py-4 md:tw-py-6">
        {data == null && loading ? (
          <Loading active />
        ) : error ? (
          <TextError error={error} />
        ) : estate == null ? (
          <TextError error={new EstateNotFoundError(t)} />
        ) : (
          <>
            <EstateEditForm estate={estate} />
            <EstateEstimatePriceCard estate={estate} refetchEstate={refetch} />
          </>
        )}
      </div>
    </div>
  )
}
