import {
  AddressCombobox,
  AddressComboboxModeEnum,
} from '@/api-adresse/AddressCombobox'
import { Loading } from '@/common/atoms/Loading'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import {
  EstateAddFormValues,
  useAddEstateSchema,
} from '@/estate/hooks/useAddEstateSchema'
import { SellerSelect } from '@/seller/components/SellerSelect'
import { useSellerMemberId } from '@/seller/hooks/useSellerMemberId'
import { useCreateEstateMutation, useGetSellerMemberByIdQuery } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type EstateAddFormProps = {
  onCreate: (id: string) => void
}
export const EstateAddForm = ({ onCreate }: EstateAddFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [createEstate, { loading }] = useCreateEstateMutation({
    refetchQueries: ['getEstates'],
  })
  const sellerMemberId = useSellerMemberId()
  const { data: getSellerByIdData } = useGetSellerMemberByIdQuery({
    variables: {
      id: sellerMemberId!,
    },
    skip: !sellerMemberId,
  })
  const sellerMember = getSellerByIdData?.seller_member_by_pk

  const { schema } = useAddEstateSchema()

  const defaultValues = {
    name: '',
    address: '',
    city: '',
    zipCode: '',
    sellerId: sellerMember?.sellerId,
  }

  const form = useForm<EstateAddFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onSubmit = async (values: EstateAddFormValues) => {
    const result = await createEstate({
      variables: {
        values,
      },
    })
    const errors = result?.errors
    if (errors) {
      console.error(errors)
      toast({
        variant: 'destructive',
        description: t('EstateAddForm.toast.error'),
      })
    }
    const estateId = result?.data?.insert_estate_one?.id
    if (!estateId) {
      console.error('no estate id returned from createEstate mutation')
      toast({
        variant: 'destructive',
        description: t('EstateAddForm.toast.error'),
      })
    } else {
      onCreate(estateId)
      toast({
        variant: 'success',
        description: t('EstateAddForm.toast.success'),
      })
    }
  }

  if (loading) return <Loading center active />

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="tw-space-y-5">
          <FormField
            control={form.control}
            name="sellerId"
            render={({ field }) => (
              <SellerSelect
                field={field}
                className={sellerMember ? 'tw-hidden' : undefined}
                filter={(seller) => !seller.archived}
              />
            )}
          />
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('EstateAddForm.name')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>{t('EstateAddForm.address')} *</FormLabel>
                  <FormControl>
                    <AddressCombobox
                      {...field}
                      onSelectItem={({
                        addressLabel,
                        postalCode,
                        city,
                        country,
                      }) => {
                        form.setValue('address', addressLabel)
                        form.setValue('zipCode', postalCode ?? '')
                        form.setValue('city', city)
                        // TODO add country
                        // form.setValue('country', country)
                      }}
                      mode={AddressComboboxModeEnum.ADDRESS}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
          <FormField
            control={form.control}
            name="zipCode"
            render={({ field }) => {
              // const handleSelect = (value: Address) => {
              //   form.setValue('zipCode', value.zipCode ?? '')
              //   form.setValue('city', value.city ?? '')
              // }
              return (
                <FormItem>
                  <FormLabel>{t('EstateAddForm.zipCode')} *</FormLabel>
                  <FormControl>
                    <AddressCombobox
                      {...field}
                      onSelectItem={({ postalCode, city, country }) => {
                        form.setValue('zipCode', postalCode ?? '')
                        form.setValue('city', city)
                        // TODO add country
                        // form.setValue('country', country)
                      }}
                      mode={AddressComboboxModeEnum.ZIP_CODE}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>{t('EstateAddForm.city')} *</FormLabel>
                  <FormControl>
                    <AddressCombobox
                      {...field}
                      onSelectItem={({ city, country }) => {
                        form.setValue('city', city)
                        // TODO add country
                        // form.setValue('country', country)
                      }}
                      mode={AddressComboboxModeEnum.CITY}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
          <Button variant="green" type="submit">
            {t('EstateAddForm.submit')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
